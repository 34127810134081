<template>
  <v-card
    :style="`width: 100% !important; height: 100% !important; overflow: hidden; max-height: 100%; ${contentStyle}`"
  >
    <v-toolbar
      dense
      flat
      :style="titleStyle"
      v-if="widget?.title !== undefined && widget?.title !== ''"
    >
      <v-toolbar-title>{{ widget.title }}</v-toolbar-title>
    </v-toolbar>

    <v-card-text style="max-height: 90%; overflow-y: scroll">
      <v-data-table
        :headers="headers"
        :items="weather"
        :loading="fetching"
        hide-default-footer
        class="overflow-y-hidden"
        style="background: transparent"
        dense
        disable-pagination
      >
        <template v-slot:[`item.wIcon`]="{ item }">
          <v-icon color="primary">{{ item.wIcon }}</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
const SMHI = require("smhi").Forecasts;
import WeatherHelper from "@/_helpers/weather";
import widgetMixin from "../../../_helpers/widgetMixin";

export default {
  name: "Weather",

  mixins: [widgetMixin],

  props: {
    widget: { default: undefined },
  },

  data() {
    return {
      headers: [
        { text: this.$t("weather.icon"), value: "wIcon" },
        { text: this.$t("weather.temp"), value: "temp" },
        { text: this.$t("weather.precipitation"), value: "precipitation" },
        { text: this.$t("weather.windDir"), value: "windDir" },
        { text: this.$t("weather.windSpeed"), value: "windSpeed" },
        { text: this.$t("weather.humidity"), value: "humidity" },
        { text: this.$t("weather.thunder"), value: "thunder" },
        { text: this.$t("weather.date"), value: "date" },
      ],
      items: [],
      weather: [],
      fetching: false,
    };
  },

  methods: {
    async loadData() {
      if (this.fetching === false) {
        let latLng = undefined;
        try {
          latLng = JSON.parse(this.widget?.content);
        } catch {
          latLng = undefined;
        }

        if (latLng === undefined) {
          console.error("Lat and Long was not present to fetch SMHI data");
          return;
        }

        this.fetching = true;
        SMHI.GetPointForecast(latLng.latitude, latLng.longitude).on(
          "loaded",
          (data) => {
            data.timeSeries.forEach((e) => {
              let t = {};
              e.parameters.forEach((p) => {
                if (p.name === "t") t.temp = p.values[0] + "°C";
                if (p.name === "wd") t.windDir = p.values[0] + "°";
                if (p.name === "ws") t.windSpeed = p.values[0] + "m/s";
                if (p.name === "r") t.humidity = p.values[0] + "%";
                if (p.name === "tstm") t.thunder = p.values[0] + "%";
                if (p.name === "pmean") t.precipitation = p.values[0] + "mm/h";

                if (p.name === "Wsymb2")
                  t.wIcon = WeatherHelper.getWeatherIcon(p.values[0]);
              });

              t.date = this.humanDate(e.validTime, "dd MMM - HH:mm");
              this.weather.push(t);
            });
          }
        );

        this.fetching = false;
      }
    },
  },

  async created() {
    await this.loadData();
  },
};
</script>
