<template>
  <div
    :style="`background-color: ${soTheme.background}; width: 100%;`"
    class="pa-3 rounded-lg"
  >
    <div class="d-flex justify-space-between align-center">
      <h3 style="font-weight: bolder" class="mr-2">
        {{ $t("valueForm.target") }}
      </h3>
      <v-autocomplete
        v-model="op"
        :items="ops"
        item-text="text"
        item-value="op"
        style="max-width: 100px"
        class="mx-2"
      >
        <template v-slot:selection="{ item }">
          <span v-html="item.op" />
        </template>
        <template v-slot:item="{ item }">
          <span v-html="item.text" />
        </template>
      </v-autocomplete>

      <v-text-field v-model="target" :label="$t('valueForm.target')" />
    </div>

    <v-autocomplete
      :label="$t('valueForm.actionType')"
      v-model="actionType"
      :items="actionTypes"
      item-text="text"
      item-value="value"
      style="max-width: 75px"
      class="mx-2"
      @change="() => (content = '')"
      v-if="!hideActionSelector"
    />

    <div class="d-flex justify-start align center flex-wrap">
      <color-picker
        v-if="actionType === 'color' && !oneColor"
        v-model="colorBackground"
        :label="$t('valueForm.actionTypes.desc.colorBackground')"
        class="mx-4"
      />

      <color-picker
        v-if="actionType === 'color' && !oneColor"
        v-model="colorBorder"
        :label="$t('valueForm.actionTypes.desc.colorBorder')"
        class="mx-4"
      />

      <color-picker
        v-if="actionType === 'color' && !oneColor"
        v-model="colorText"
        :label="$t('valueForm.actionTypes.desc.colorText')"
        class="mx-4"
      />

      <v-text-field
        v-if="actionType === 'value' && !oneColor"
        v-model="content"
        :label="$t('valueForm.actionTypes.desc.value')"
      />

      <color-picker
        v-if="actionType === 'color' && oneColor"
        v-model="colorBackground"
        :label="label"
        class="mx-4"
      />
    </div>

    <v-btn style="width: 100%" color="primary" @click="add">{{
      $t("common.add")
    }}</v-btn>
  </div>
</template>

<script>
import widgetMixin from "../../../_helpers/widgetMixin";

import ColorPicker from "./ColorPicker.vue";

const defaults = {
  target: "",
  op: "==",
  content: "",
  colorBackground: undefined,
  colorBorder: undefined,
  colorText: undefined,
  actionType: undefined,
};

export default {
  name: "ActionForValue",

  components: { ColorPicker },

  mixins: [widgetMixin],

  props: {
    value: {
      default: undefined,
    },

    label: {
      default: "",
    },

    defaultActionType: {
      default: "color",
    },

    oneColor: {
      default: false,
      type: Boolean,
    },

    hideActionSelector: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    let res = defaults;

    res.actionType = this.defaultActionType;
    return res;
  },

  computed: {
    actionTypes() {
      return [
        { text: this.$t("valueForm.actionTypes.color"), value: "color" },
        { text: this.$t("valueForm.actionTypes.value"), value: "value" },
      ];
    },
    ops() {
      return [
        { text: this.$t("valueForm.ops.eq"), op: "==" },
        { text: this.$t("valueForm.ops.notEq"), op: "!=" },
        { text: this.$t("valueForm.ops.greater"), op: ">" },
        { text: this.$t("valueForm.ops.less"), op: "<" },
        { text: this.$t("valueForm.ops.greaterOrEq"), op: ">=" },
        { text: this.$t("valueForm.ops.lessOrEq"), op: "<=" },
      ];
    },
  },

  methods: {
    resetFields(resetJson = undefined) {
      // Reset fields
      this.target = resetJson?.target ?? defaults?.target;
      this.op = resetJson?.op ?? defaults?.op;
      this.content = resetJson?.content ?? defaults?.content;
      this.colorBackground =
        resetJson?.colorBackground ?? defaults?.colorBackground;
      this.colorBorder = resetJson?.colorBorder ?? defaults?.colorBorder;
      this.colorText = resetJson?.colorText ?? defaults?.colorText;
      this.actionType = resetJson?.actionType ?? this.defaultActionType;
    },

    add() {
      this.$emit(
        "input",
        JSON.stringify({
          op: this.op,
          target: this.target,
          actionType: this.actionType,
          content: this.content,
          colors: {
            background: this.colorBackground,
            border: this.colorBorder,
            text: this.colorText,
          },
        })
      );

      this.resetFields();
    },
  },

  watch: {
    value(val) {
      if (val === undefined || val === "" || typeof val != "string") return;

      let json = this.tryParseJson(val);
      if (json !== undefined) {
        this.resetFields(json);
      }
    },
  },
};
</script>