<template>
  <div>
    <h3>
      {{ $t("imageMap.fontSelectorTitle") }}
    </h3>
    <v-divider class="pa-2" />
    <div class="d-flex justify-space-between align-center" :key="valKey">
      <div
        v-for="(s, i) in sizes"
        :key="i"
        :class="`font-chip d-flex justify-center align-center ${
          size === s ? 'primary white--text' : ''
        }`"
        :style="`font-size: ${s}px; font-weight: bolder;`"
        @click="() => (size = s)"
      >
        <span>EX</span>
      </div>
    </div>
  </div>
</template>

<script>
import widgetMixin from "../../../../_helpers/widgetMixin";

export default {
  name: "WidgetImageMapFontSelector",

  mixins: [widgetMixin],

  props: {
    value: {
      type: Number,
      default: 14,
    },
  },

  data() {
    return { valKey: 0 };
  },

  computed: {
    size: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.valKey++;
      },
    },

    sizes() {
      return [8, 12, 14, 18, 22, 24];
    },
  },

  methods: {},
};
</script>

<style scoped>
.font-chip {
  border: 1px solid black;
  padding: 0.5rem;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  text-align: center;
  cursor: pointer;
  margin: auto;
}
</style>