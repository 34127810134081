var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-2"},[_c('div',{staticStyle:{"min-height":"300px"}},[_c('widget',{key:_vm.widgetKey,staticStyle:{"height":"300px"},attrs:{"value":_vm.widget,"hide-buttons":""}})],1),_c('div',{staticClass:"colors-container"},_vm._l((_vm.templateColorsByType(_vm.widget.widgetType)),function(formType,i){return _c('div',{key:i},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.$t(`colorPicker.${formType}`))+" ")]),(
          _vm.isType(
            [
              _vm.templateColorKeys.FILLLEVEL_COLOR,
              _vm.templateColorKeys.TITLE_TEXT,
              _vm.templateColorKeys.TITLE_BACKGROUND,
              _vm.templateColorKeys.CONTENT_TEXT,
              _vm.templateColorKeys.CONTENT_BACKGROUND,
            ],
            formType
          )
        )?_c('div',[_c('color-picker',{attrs:{"value":_vm.getMeta(formType)?.value},on:{"input":(val) => {
              _vm.addMeta(formType, val);
              _vm.forceRerender();
            }}})],1):(formType === _vm.templateColorKeys.PER_SLOT)?_c('div',_vm._l((_vm.widget.slots),function(slt,i){return _c('div',{key:i},[_c('span',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(" "+_vm._s(`Node Slot ${slt.index} - ${slt.key}`)+" ")]),_c('color-picker',{attrs:{"value":_vm.getMeta(formType, slt.slotId)?.value},on:{"input":(val) => {
                _vm.addMeta(formType, val, slt.slotId);
                _vm.forceRerender();
              }}})],1)}),0):(formType === _vm.templateColorKeys.GAUGE_COLOR)?_c('div',[_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"width":"100%"}},[_c('color-picker',{model:{value:(_vm.gaugeColorValue),callback:function ($$v) {_vm.gaugeColorValue=$$v},expression:"gaugeColorValue"}}),_c('v-slider',{attrs:{"max":"100","min":"0","color":"accent","thumb-label":"","dense":"","hide-details":""},model:{value:(_vm.gaugeSliderValue),callback:function ($$v) {_vm.gaugeSliderValue=$$v},expression:"gaugeSliderValue"}}),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.addSliderColor}},[_vm._v(_vm._s(_vm.$t("common.add")))])],1),_c('div',{staticClass:"d-flex flex-wrap pa-2"},_vm._l((_vm.getMetas(formType)),function(clr){return _c('div',{key:clr.templateWidgetMetaId,staticClass:"d-flex align-center justify-center rounded-lg pa-1 ma-1",style:(`background-color: ${clr.value}${
              clr.value.length <= 7 ? '99' : ''
            }; border: 1px solid #9e9e9e39`)},[_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(clr.content)+" % ")]),_c('v-btn',{attrs:{"icon":""},on:{"click":() => {
                  _vm.removeMeta(clr.templateWidgetMetaId);
                  _vm.forceRerender();
                }}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)}),0)]):(formType === _vm.templateColorKeys.COLOR_FOR_EXPRESSION)?_c('div',[_c('color-for-expression',{model:{value:(_vm.widget),callback:function ($$v) {_vm.widget=$$v},expression:"widget"}})],1):_vm._e(),_c('v-divider')],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }