<template>
  <div style="position: relative; display: inline">
    <h3>{{ label ?? "" }}</h3>
    <div class="d-flex align-center justify-start">
      <v-btn
        color="gray lighten-2 mr-4"
        x-small
        dark
        @click="dialog = !dialog"
        fab
      >
        <v-icon>mdi-palette</v-icon>
      </v-btn>

      <span class="subtitle d-flex">
        <div
          @click="dialog = true"
          class="color-block color-block-border mr-2"
          :style="`${
            localValue !== '' ? 'background-color: ' + localValue + '' : ''
          }`"
        ></div>
        {{ localValue }}
      </span>
      <v-btn v-if="localValue !== ''" icon @click="localValue = ''"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>

    <div
      :class="`${
        dialog ? 'd-flex flex-wrap' : 'd-none'
      } color-swatches elevation-3`"
      :style="`background-color: ${soTheme.background}`"
    >
      <v-text-field v-model="localValue" dense class="px-2" />
      <div
        v-for="(color, i) in colors"
        :key="i"
        class="color-block"
        :style="`background-color: ${color};`"
        @click="
          () => {
            localValue = color;
            dialog = false;
          }
        "
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ColorPicker",

  props: {
    label: {
      default: undefined,
    },

    value: {
      default: "",
    },
  },

  data() {
    return {
      dialog: false,
      colors: [
        "#ffffff",
        "#9e9e9e",
        "#000000",
        "#f19a91",
        "#e57a81",
        "#ab5763",
        "#FFCC80",
        "#FFB74D",
        "#FFA726",
        "#f5deab",
        "#eecf74",
        "#eadb2f",
        "#bfdcdb",
        "#89cabd",
        "#68bab3",
        "#b9def3",
        "#87abc5",
        "#286fa3",
      ],
    };
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.$emit("change");
      },
    },
  },
};
</script>

<style lang="scss">
.color-swatches {
  position: absolute;
  width: 165px;
  border-radius: 5px;
  z-index: 999;
}

.color-block {
  width: 55px;
  height: 25px;
  cursor: pointer;
}

.color-block-border {
  border-radius: 5px;
  border: 1px solid #999;
}
</style>
