var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"height":"100%","overflow":"hidden"}},[_c('div',{staticClass:"py-0 d-flex flex-column justify-start align-stretch",staticStyle:{"height":"100%"}},[(_vm.hasTitle)?_c('v-col',{style:(`height: 55px; max-height: ${
        _vm.fontStyle.title.size + 3
      }rem; ${_vm.titleStyle}`),attrs:{"no-gutters":"","align":"start"}},[_c('span',{class:`${_vm.fontStyle.title.weight} text-left`,style:(`width: 100%; font-size: ${_vm.fontStyle.title.size}rem; ${_vm.titleStyle}`)},[_vm._v(" "+_vm._s(_vm.widget.title)+" ")])]):_vm._e(),_vm._l((_vm.slotsWithData),function(slt,i){return _c('div',{key:i,staticClass:"grow"},[_c('v-row',{class:`pa-0 ma-0 ${!_vm.horizontalTitle ? 'flex-column' : ''}`,staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"pa-1 d-flex justify-center align-center flex-column",style:(`${_vm.titleStyle ?? 'background-color: #9e9e9e1f'}`),attrs:{"no-gutters":""}},[_c('span',{class:`${_vm.fontStyle.title.weight} text-left pl-2`,style:(`width: 100%; font-size: ${
              _vm.fontStyle.title.size * 0.9
            }rem;`)},[_vm._v(" "+_vm._s(_vm.name(slt))+" ")]),_c('span',{class:`${_vm.fontStyle.title.weight} text-left pl-2`,style:(`width: 100%; font-size: ${
              _vm.fontStyle.title.size * 0.6
            }rem;`)},[_vm._v(" "+_vm._s(_vm.date(slt))+" ")])]),_c('v-col',{class:`ma-0 d-flex justify-center align-center flex-column ${
            _vm.horizontalTitle ? 'pa-0' : 'py-4'
          }`,style:(_vm.contentStyle),attrs:{"no-gutters":""}},[_c('div',[_c('span',{class:`text-center ${_vm.fontStyle.content.weight}`,style:(`width: 100%; font-size: ${_vm.fontStyle.content.size}rem`)},[_vm._v(" "+_vm._s(_vm.slotValue(slt))+" ")]),(!_vm.hideTrendIcon)?_c('v-icon',{attrs:{"large":_vm.fontStyle.content.size < 2.8,"x-large":_vm.fontStyle.content.size > 2.8,"color":_vm.getTrendColor(slt)}},[_vm._v(_vm._s(_vm.getTrendIcon(slt)))]):_vm._e()],1),(_vm.sparkLine)?_c('sprkline',{staticStyle:{"width":"100%"},attrs:{"sparkline-data":_vm.sparklineData(slt),"color":"default","label-color":"default"}}):_vm._e()],1)],1),_c('v-divider')],1)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }