var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.id !== undefined)?_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$t("imageMap.nodeSelection"))+" ")]),_c('v-expansion-panel-content',[_c('div',{staticClass:"d-flex justify-space-between align-center mb-3"},[_c('v-autocomplete',{attrs:{"value":_vm.getAssociatedMeta(_vm.metaKeys.IMAGEMAP_NODE, _vm.id)?.content,"items":_vm.tags,"label":_vm.$t('tag.picker.step1'),"item-text":"name","item-value":"deveui","prepend-icon":"mdi-lock-open"},on:{"input":(v) => {
              _vm.addAssociatedMeta(_vm.metaKeys.IMAGEMAP_NODE, v, _vm.id);
              _vm.$emit('update-markers');
            },"change":_vm.fetchKeysForTag}}),_c('v-autocomplete',{attrs:{"value":_vm.getAssociatedMeta(_vm.metaKeys.IMAGEMAP_NODE_KEY, _vm.id)?.content,"items":_vm.keys,"label":_vm.$t('tag.picker.step2'),"item-text":"label","item-value":"key","prepend-icon":"mdi-key"},on:{"input":(v) => {
              _vm.addAssociatedMeta(_vm.metaKeys.IMAGEMAP_NODE_KEY, v, _vm.id);
              _vm.$emit('update-markers');
            }}})],1)])],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$t("imageMap.iconFormTitle"))+" ")]),_c('v-expansion-panel-content',[_c('div',{staticClass:"d-flex justify-space-between align-center mb-3"},[_c('v-slider',{attrs:{"value":_vm.getAssociatedMeta(_vm.metaKeys.IMAGEMAP_ICON_WIDTH, _vm.id)?.content ?? 45,"thumb-label":"always","label":_vm.$t('imageMap.icon.width')},on:{"input":(v) => {
              _vm.addAssociatedMeta(_vm.metaKeys.IMAGEMAP_ICON_WIDTH, v, _vm.id);
              _vm.$emit('update-markers');
            }}}),_c('v-slider',{attrs:{"value":_vm.getAssociatedMeta(_vm.metaKeys.IMAGEMAP_ICON_HEIGHT, _vm.id)?.content ??
            45,"thumb-label":"always","label":_vm.$t('imageMap.icon.height')},on:{"input":(v) => {
              _vm.addAssociatedMeta(_vm.metaKeys.IMAGEMAP_ICON_HEIGHT, v, _vm.id);
              _vm.$emit('update-markers');
            }}})],1),_c('widget-image-map-font-selector',{attrs:{"value":_vm.getAssociatedMeta(_vm.metaKeys.IMAGEMAP_FONTSTYLE, _vm.id)?.content ?? 14},on:{"input":(v) => {
            _vm.addAssociatedMeta(_vm.metaKeys.IMAGEMAP_FONTSTYLE, v, _vm.id);
            _vm.$emit('update-markers');
          }}}),_c('div',{staticClass:"d-flex flex-wrap justify-space-between align-center py-2"},[(_vm.iconHasColor[_vm.iconKey(_vm.id)] ?? false)?_c('color-picker',{attrs:{"label":_vm.$t('imageMap.colors.background'),"value":_vm.getAssociatedMeta(_vm.metaKeys.IMAGEMAP_ICON_COLOR_BACKGROUND, _vm.id)
              ?.content},on:{"input":(v) => {
              _vm.addAssociatedMeta(
                _vm.metaKeys.IMAGEMAP_ICON_COLOR_BACKGROUND,
                v,
                _vm.id
              );
              _vm.$emit('update-markers');
            }}}):_vm._e(),(_vm.iconHasColor[_vm.iconKey(_vm.id)] ?? false)?_c('color-picker',{attrs:{"label":_vm.$t('imageMap.colors.border'),"value":_vm.getAssociatedMeta(_vm.metaKeys.IMAGEMAP_ICON_COLOR_BORDER, _vm.id)
              ?.content},on:{"input":(v) => {
              _vm.addAssociatedMeta(_vm.metaKeys.IMAGEMAP_ICON_COLOR_BORDER, v, _vm.id);
              _vm.$emit('update-markers');
            }}}):_vm._e(),_c('color-picker',{attrs:{"label":_vm.$t('imageMap.colors.text'),"value":_vm.getAssociatedMeta(_vm.metaKeys.IMAGEMAP_ICON_COLOR_TEXT, _vm.id)?.content},on:{"input":(v) => {
              _vm.addAssociatedMeta(_vm.metaKeys.IMAGEMAP_ICON_COLOR_TEXT, v, _vm.id);
              _vm.$emit('update-markers');
            }}})],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$t("imageMap.valueFormPanel"))+" ")]),_c('v-expansion-panel-content',[_c('action-for-value',{on:{"input":(v) => {
            _vm.addAssociatedMeta(
              this.metaKeys.IMAGEMAP_ACTION_FOR_VALUE,
              v,
              _vm.id,
              false,
              true
            );
          }}}),_c('div',{staticClass:"d-flex flex-wrap justify-start align-center my-2"},_vm._l((_vm.getAssociatedMetas(
            this.metaKeys.IMAGEMAP_ACTION_FOR_VALUE,
            _vm.id,
            true
          )),function(item){return _c('action-for-value-chip',{key:item.templateWidgetMetaId,staticClass:"ma-1",attrs:{"content":item.content},on:{"remove":function($event){return _vm.removeMeta(item.templateWidgetMetaId)}}})}),1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }