<template>
  <v-dialog v-model="localState" max-width="700">
    <v-card style="overflow-y: scroll">
      <v-toolbar dark class="mb-2">
        <v-btn icon dark @click="localState = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>TODO: Title</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text style="height: 450px">
        <widget-image-map
          v-if="localState"
          ref="wimap"
          :widget="widget"
          edit-mode
          @map-click="(e) => mapClicked(e)"
          @marker-click="(e) => markerClicked(e)"
        />
      </v-card-text>

      <v-card-text
        v-if="currentMetaId !== undefined && getMetaById(currentMetaId)"
      >
        <widget-image-map-marker-form
          :id="currentMetaId"
          v-model="widget"
          @update-markers="updateMarkers"
        />
      </v-card-text>

      <v-card-actions>
        <v-btn
          text
          small
          color="gray"
          type="submit"
          @click="localState = false"
          x-large
        >
          {{ $t("common.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import widgetMixin from "../../../../_helpers/widgetMixin";
import WidgetImageMap from "../WidgetImageMap.vue";
import WidgetImageMapMarkerForm from "./WidgetImageMapMarkerForm.vue";

const FORMTYPES = {
  NEW_NODE: 0,
  EXISTING_NODE: 1,
};

export default {
  name: "WidgetImageMapEditor",

  mixins: [widgetMixin],

  components: {
    WidgetImageMap,
    "widget-image-map-marker-form": WidgetImageMapMarkerForm,
  },

  props: {
    mdlWidget: {
      default: null,
    },

    state: {
      default: false,
    },
  },

  model: {
    prop: "mdlWidget",
  },

  data() {
    return {
      markerKey: 1,
      currentMetaId: undefined,
      formType: FORMTYPES.NEW_NODE,
    };
  },

  methods: {
    updateMarkers() {
      if (this.$refs?.wimap?._updateMarkers === undefined) return;

      this.$refs.wimap.setMarkers();
      this.$refs.wimap._updateMarkers();
    },

    mapClicked(e) {
      if (e.latlng?.lat === undefined || e.latlng?.lng === undefined) return;

      let id = crypto.randomUUID();
      this.meta.push({
        templateWidgetMetaId: id,
        key: this.metaKeys.IMAGEMAP_MARKERS,
        content: JSON.stringify({
          lat: e.latlng.lat,
          lng: e.latlng.lng,
        }),
      });
      this.currentMetaId = id;
      this.formType = FORMTYPES.NEW_NODE;

      this.updateMarkers();
    },

    markerClicked(e) {
      if (e?.metaId === undefined || e.metaId === "") return;

      this.currentMetaId = e.metaId;
    },
  },

  computed: {
    localState: {
      get() {
        return this.state;
      },

      set(val) {
        this.$emit("state-update", val);
      },
    },

    widget: {
      get() {
        return this.mdlWidget;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>