<template>
  <v-card
    :style="`width: 100% !important; height: 100% !important; overflow: auto ${contentStyle}`"
  >
    <v-toolbar
      dense
      flat
      :style="titleStyle"
      v-if="widget?.title !== undefined && widget?.title !== ''"
    >
      <v-toolbar-title>{{ widget.title }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text
      v-if="
        !getMetaValueAsBool(metaKeys.IGNORE_BODY) &&
        widget?.content !== undefined
      "
    >
      <div v-html="widget?.content" :style="contentStyle"></div>
    </v-card-text>
  </v-card>
</template>

<script>
import widgetMixin from "../../../_helpers/widgetMixin";
export default {
  name: "TextWidget",
  props: ["widget"],
  mixins: [widgetMixin],

  data() {
    return {};
  },

  methods: {},

  watch: {},

  created() {},
};
</script>

<style>
.table-borders > table td,
.table-borders > table th {
  min-width: 4em;
  border: 1px solid #ddd;
  padding: 3px 5px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.table-borders > pre {
  padding: 0.7rem 1rem;
  border-radius: 5px;
  background-color: rgb(47, 46, 44);
  font-size: 1rem;
}
</style>
