import Meta from "./ModuleMeta";
import FontMeta from "./FontMeta";
import CE from "./CsharpEnum";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("tag", ["tagsWithMeta"]),

    shouldFake() {
      return this.tags?.length <= 0 ?? true;
    },

    moduleMeta() {
      return Meta;
    },

    metaKeys() {
      return Meta.Enum;
    },

    widgetTypes() {
      return CE.ModuleType;
    },

    ignoreWidgetTypes() {
      return [
        CE.ModuleType.TRENDCHART,
        CE.ModuleType.CONTROLSLIDERS,
        CE.ModuleType.SENSOROVERVIEW,
        CE.ModuleType.INFOCARD,
        CE.ModuleType.TILTCHART,
        CE.ModuleType.MAP,
        CE.ModuleType.FUNCTIONBUTTON,
      ];
    },

    ignoreMetaKeys() {
      return [
        this.metaKeys.DEVEUI,
        this.metaKeys.SYNC_Y,
        this.metaKeys.ALARM_ID,
        this.metaKeys.FONT_STYLE,
        this.metaKeys.FONT_WEIGHT,
        this.metaKeys.ZOOM_SLIDER,
      ];
    },

    fontWeights() {
      return FontMeta.weights;
    },

    fontSizes() {
      return FontMeta.sizes;
    },

    fontTypes() {
      let activeTypes = FontMeta.moduleUsedTypes.find(
        (t) => t.key == this.widget?.widgetType
      );

      return Object.values(FontMeta.types).filter((t) =>
        activeTypes.value.includes(t.value)
      );
    },

    widgetSlots() {
      return this.widget?.slots ?? undefined;
    },

    templateColorKeys() {
      return {
        COLOR_FOR_EXPRESSION: "so_style_meta_color_for_expression",
        CONTENT_BACKGROUND: "so_style_meta_content_background",
        CONTENT_TEXT: "so_style_meta_content_text",
        FILLLEVEL_COLOR: "so_style_meta_fill_level_color",
        GAUGE_COLOR: "so_style_meta_gauge_color",
        PER_SLOT: "so_style_meta_per_slot",
        TITLE_BACKGROUND: "so_style_meta_title_background",
        TITLE_TEXT: "so_style_meta_title_text",
      };
    },

    templateColorTypes() {
      return {
        [CE.ModuleType.GAUGE]: [
          this.templateColorKeys.GAUGE_COLOR,
          this.templateColorKeys.TITLE_TEXT,
          this.templateColorKeys.TITLE_BACKGROUND,
          this.templateColorKeys.CONTENT_TEXT,
          this.templateColorKeys.CONTENT_BACKGROUND,
        ],
        [CE.ModuleType.MULTICHART]: [
          this.templateColorKeys.PER_SLOT,
          this.templateColorKeys.TITLE_TEXT,
          this.templateColorKeys.TITLE_BACKGROUND,
          this.templateColorKeys.CONTENT_TEXT,
          this.templateColorKeys.CONTENT_BACKGROUND,
        ],
        [CE.ModuleType.SINGLE]: [
          this.templateColorKeys.TITLE_TEXT,
          this.templateColorKeys.TITLE_BACKGROUND,
          this.templateColorKeys.CONTENT_TEXT,
          this.templateColorKeys.CONTENT_BACKGROUND,
        ],
        [CE.ModuleType.TEXT]: [
          this.templateColorKeys.TITLE_TEXT,
          this.templateColorKeys.TITLE_BACKGROUND,
          this.templateColorKeys.CONTENT_TEXT,
          this.templateColorKeys.CONTENT_BACKGROUND,
        ],
        [CE.ModuleType.IMAGE]: [
          this.templateColorKeys.TITLE_TEXT,
          this.templateColorKeys.TITLE_BACKGROUND,
        ],
        [CE.ModuleType.FILLINDICATOR]: [
          this.templateColorKeys.TITLE_TEXT,
          this.templateColorKeys.TITLE_BACKGROUND,
          this.templateColorKeys.CONTENT_TEXT,
          this.templateColorKeys.CONTENT_BACKGROUND,
          this.templateColorKeys.FILLLEVEL_COLOR,
          this.templateColorKeys.COLOR_FOR_EXPRESSION,
        ],
      };
    },

    templateColorsByType: (ctx) => (type) => {
      return ctx.templateColorTypes[type];
    },

    hasTitle() {
      return this.widget?.title !== undefined && this.widget?.title !== "";
    },

    titleStyle() {
      let titleText = this.getMeta(this.templateColorKeys.TITLE_TEXT);
      let titleBackground = this.getMeta(
        this.templateColorKeys.TITLE_BACKGROUND
      );
      if (titleText === undefined && titleBackground === undefined) return "";

      return `${
        titleText !== undefined && titleText.value !== ""
          ? `color: ${titleText.value};`
          : ""
      }${
        titleBackground !== undefined && titleBackground.value !== ""
          ? `background-color: ${titleBackground.value};`
          : ""
      }`;
    },

    titleTextColor() {
      let result = this.getMeta(this.templateColorKeys.TITLE_TEXT);

      return result;
    },

    titleBackgroundColor() {
      let result = this.getMeta(this.templateColorKeys.TITLE_BACKGROUND);

      return result;
    },

    contentStyle() {
      let contentText = this.getMeta(this.templateColorKeys.CONTENT_TEXT);
      let contentBackground = this.getMeta(
        this.templateColorKeys.CONTENT_BACKGROUND
      );
      if (contentText === undefined && contentBackground === undefined)
        return "";

      return `${
        contentText !== undefined && contentText.value !== ""
          ? `color: ${contentText.value};`
          : ""
      }${
        contentBackground !== undefined && contentBackground.value !== ""
          ? `background-color: ${contentBackground.value};`
          : ""
      }`;
    },

    contentTextColor() {
      let result = this.getMeta(this.templateColorKeys.CONTENT_TEXT);

      return result;
    },

    contentBackgroundColor() {
      let result = this.getMeta(this.templateColorKeys.CONTENT_BACKGROUND);

      return result;
    },

    meta: {
      get() {
        return this.widget?.meta ?? [];
      },

      set(val) {
        this.widget.meta = val;
      },
    },
  },

  methods: {
    colorForExpressionResult(val) {
      let metas = this.getMetas(this.templateColorKeys.COLOR_FOR_EXPRESSION);
      if (metas === undefined || metas.length <= 0) return undefined;

      for (var expression of metas) {
        let content = this.tryParseJson(expression?.content);
        if (content === undefined) continue;

        if (content?.op === undefined || content?.actionType === undefined)
          continue;

        let exp = `${isNaN(parseFloat(val)) ? `"${val}"` : val} ${content.op} ${
          isNaN(parseFloat(content.target))
            ? `"${content.target}"`
            : content.target
        }`;

        if (eval(exp)) return content?.colors?.background;
      }
    },

    tryParseJson(str) {
      try {
        if (typeof str === "object") return str;

        var json = JSON.parse(str);
        return typeof json === "object" ? json : undefined;
      } catch (e) {
        console.error("Could not parse json from value", e, str);
      }

      return undefined;
    },

    labelForMeta(key, index) {
      return Meta.InputFields[key].fieldsLabels[index];
    },

    isMetaBool(metaKey) {
      var inpField = Meta.InputFields[metaKey];
      if (inpField === undefined) return false;

      // 4 is for bool values
      return inpField.fields[4];
    },

    shouldHaveValueInput(metaKey) {
      var inpField = Meta.InputFields[metaKey];
      if (inpField === undefined) return false;
      return inpField.fields[0];
    },

    shouldHaveValueInputNumber(metaKey) {
      var inpField = Meta.InputFields[metaKey];
      if (inpField === undefined) return false;
      return (
        inpField.numberInput !== undefined && inpField.numberInput === true
      );
    },

    // Content in the new dashboard system is equal to valuematch
    shouldHaveContent(metaKey) {
      var inpField = Meta.InputFields[metaKey];
      if (inpField === undefined) return false;

      return inpField.fields[1];
    },

    shouldHaveIcon(metaKey) {
      var inpField = Meta.InputFields[metaKey];
      if (inpField === undefined) return false;

      return inpField.fields[2];
    },

    isType(target, type) {
      if (!Array.isArray(target)) {
        return target === type;
      }

      return target.includes(type);
    },

    getMetas(key) {
      if (this.meta === undefined) return undefined;

      let metas = this.meta.filter((d) => d.key === key);
      return metas ?? [];
    },

    getMeta(key, slot) {
      if (this.meta === undefined) return undefined;

      let metaIdx = this.meta.findIndex(
        (d) =>
          d.key === key &&
          (d.slotId === undefined ||
            d.slotId === null ||
            d.slotId === "" ||
            d.slotId === slot)
      );

      return metaIdx !== -1 ? this.meta[metaIdx] : undefined;
    },

    getMetaById(id) {
      if (this.meta === undefined) return undefined;

      return this.meta.find((e) => e.templateWidgetMetaId === id);
    },

    getAssociatedMeta(key, id) {
      if (this.meta === undefined) return undefined;

      return this.meta.find((e) => e.value === id && e.key === key);
    },

    getAssociatedMetas(
      key,
      id,
      tryParseContentAsJson = false,
      filterFn = () => {
        return true;
      }
    ) {
      if (this.meta === undefined) return [];

      let result = [];
      for (let m of this.meta.filter((e) => e.value === id && e.key === key) ??
        []) {
        let temp = { ...m };
        temp.contentIsJson = false;
        if (!tryParseContentAsJson) {
          result.push(temp);
          continue;
        }

        let parsed = this.tryParseJson(temp.content);
        if (parsed !== undefined) {
          temp.contentIsJson = true;
          temp.content = parsed;
        }

        result.push(temp);
      }

      return result.filter(filterFn);
    },

    getMetaValueAsBool(key) {
      if (this.meta === undefined) return undefined;

      let metaIdx = this.meta.findIndex((d) => d.key === key);

      return metaIdx !== -1
        ? this.meta[metaIdx].value === "true" ||
            this.meta[metaIdx].value === true
        : false;
    },

    getMetaValueAsBoolWithSlot(key, slot) {
      if (this.meta === undefined) return undefined;

      let meta = this.getMeta(key, slot);

      return meta?.value !== undefined
        ? meta.value === "true" || meta.value === true
        : false;
    },

    removeMeta(id) {
      let metaId = this.meta.findIndex((d) => d.templateWidgetMetaId === id);
      if (metaId === -1) return;

      this.meta.splice(metaId, 1);
    },

    // Add / Modify meta, if the multi variable is
    // set we add a new meta even if there is meta
    // with key and slot present in metas
    addMeta(
      key,
      val,
      slot,
      defKey = "value",
      metaId = undefined,
      multi = false
    ) {
      if (this.meta === undefined) return;
      // If metaId is not undefined we should only look for for that meta instead of creating a whole new meta
      let metaIdx = -1;
      if (metaId !== undefined)
        metaIdx = this.meta.findIndex((d) => d.templateWidgetMetaId === metaId);
      else if (!multi)
        metaIdx = this.meta.findIndex(
          (d) =>
            d.key === key &&
            (d.slotId === undefined || d.slotId === null || d.slotId === slot)
        );

      if (metaIdx !== -1) {
        this.meta[metaIdx][defKey] = val;
        return;
      }

      let newMeta = {
        templateWidgetMetaId: metaId ?? crypto.randomUUID(),
        key: key,
        slotId: slot,
      };
      newMeta[defKey] = val;
      this.meta.push(newMeta);
    },

    addAssociatedMeta(
      key,
      payload,
      associatedId = undefined,
      stringifyPayload = false,
      additive = false
    ) {
      if (associatedId === undefined) return;

      if (!additive) {
        let metaIdx = this.meta.findIndex(
          (e) => e.value === associatedId && e.key === key
        );
        if (metaIdx !== -1) {
          this.meta[metaIdx].content = stringifyPayload
            ? JSON.stringify(payload)
            : payload;
          return;
        }
      }

      let newMeta = {
        templateWidgetMetaId: crypto.randomUUID(),
        key: key,
        value: associatedId,
        content: stringifyPayload ? JSON.stringify(payload) : payload,
      };

      this.meta.push(newMeta);
    },

    // Get tag for index or the first tag as default. If there is no tags in the array we return null
    getTagIdForSlotIndex(tagIds, index) {
      if (typeof tagIds !== "object") {
        console.error("TagIds are not array could not get tag at index");
        return null;
      }

      return tagIds[index] ?? tagIds[0] ?? null;
    },

    // Values are expected to be as a tagDataDto
    getPrioritizedSlot(tags, values) {
      let firstSlot = this.widget?.slots[0] ?? null;
      if (typeof values !== "object") {
        console.error(
          "Slot values are not array cannot find prioritized slot selecting first slot possible without matching data or null " +
            firstSlot
        );
        return firstSlot;
      }

      if (typeof tags !== "object" || tags.length <= 0)
        console.error("Not enough tags to find prioritzed slots");
      else
        for (var slot of this.widget?.slots ?? []) {
          let tagId = this.getTagIdForSlotIndex(tags, slot.index);
          if (tagId === null) continue;

          var foundValue = values.find(
            (d) => d != null && d.tagDeveui == tagId && d.key == slot.key
          );

          if (foundValue != undefined) return slot;
        }

      return firstSlot;
    },

    // Construct deveui and keys for fetching data from the api
    getDeveuiAndKeysForSlots(tags) {
      let i = 1;
      let deveuiAndKeyCombinations = [];
      for (var tag of tags) {
        for (var slot of this.widget?.slots?.filter((d) => d.index == i) ?? [])
          deveuiAndKeyCombinations.push({ deveui: tag, key: slot.key });
        i++;
      }

      return deveuiAndKeyCombinations;
    },

    // Construct deveui and keys for fetching data from the api
    getDeveuiAndKeysForSlotsFromAllWidgets(tags) {
      let i = 1;
      let deveuiAndKeyCombinations = [];
      let slots = [];
      for (var w of this.widgets) {
        for (var s of w.slots) {
          if (slots.find((d) => d.slotId == s.slotId)) continue;

          slots.push(s);
        }
      }

      for (var tag of tags) {
        for (var slot of slots.filter((d) => d.index == i) ?? [])
          deveuiAndKeyCombinations.push({ deveui: tag, key: slot.key });
        i++;
      }

      return deveuiAndKeyCombinations;
    },

    getSingleValueFromPrioritizedSlot(tags, values) {
      var slot = this.getPrioritizedSlot(tags, values);
      if (slot === null) return "N/A";

      var tagId = this.getTagIdForSlotIndex(tags, slot.index);
      if (tagId === null) return "N/A";

      var val = values.find(
        (d) => d != null && d.tagDeveui == tagId && d.key == slot.key
      );
      return val?.value ?? "N/A";
    },

    constructLabelFromTagAndKey(tag, key) {
      var storeTagWithKeys = this.tagsWithMeta[tag];
      if (storeTagWithKeys === undefined) return `${tag} - ${key}`;

      return `${storeTagWithKeys.name} - ${
        storeTagWithKeys.keys?.find((d) => d.key == key)?.label ?? key
      }`;
    },
  },
};
