<template>
  <v-card style="height: 100%; overflow: hidden">
    <div
      style="height: 100%"
      class="py-0 d-flex flex-column justify-start align-stretch"
    >
      <v-col
        v-if="hasTitle"
        no-gutters
        align="start"
        :style="`height: 55px; max-height: ${
          fontStyle.title.size + 3
        }rem; ${titleStyle}`"
      >
        <span
          :style="`width: 100%; font-size: ${fontStyle.title.size}rem; ${titleStyle}`"
          :class="`${fontStyle.title.weight} text-left`"
        >
          {{ widget.title }}
        </span>
      </v-col>

      <div v-for="(slt, i) in slotsWithData" :key="i" class="grow">
        <v-row
          :class="`pa-0 ma-0 ${!horizontalTitle ? 'flex-column' : ''}`"
          style="height: 100%"
        >
          <v-col
            no-gutters
            class="pa-1 d-flex justify-center align-center flex-column"
            :style="`${titleStyle ?? 'background-color: #9e9e9e1f'}`"
          >
            <span
              :class="`${fontStyle.title.weight} text-left pl-2`"
              :style="`width: 100%; font-size: ${
                fontStyle.title.size * 0.9
              }rem;`"
            >
              {{ name(slt) }}
            </span>
            <span
              :class="`${fontStyle.title.weight} text-left pl-2`"
              :style="`width: 100%; font-size: ${
                fontStyle.title.size * 0.6
              }rem;`"
            >
              {{ date(slt) }}
            </span>
          </v-col>
          <v-col
            no-gutters
            :class="`ma-0 d-flex justify-center align-center flex-column ${
              horizontalTitle ? 'pa-0' : 'py-4'
            }`"
            :style="contentStyle"
          >
            <div>
              <span
                :class="`text-center ${fontStyle.content.weight}`"
                :style="`width: 100%; font-size: ${fontStyle.content.size}rem`"
              >
                {{ slotValue(slt) }}
              </span>

              <v-icon
                v-if="!hideTrendIcon"
                :large="fontStyle.content.size < 2.8"
                :x-large="fontStyle.content.size > 2.8"
                :color="getTrendColor(slt)"
                >{{ getTrendIcon(slt) }}</v-icon
              >
            </div>
            <sprkline
              v-if="sparkLine"
              :sparkline-data="sparklineData(slt)"
              color="default"
              label-color="default"
              style="width: 100%"
            />
          </v-col>
        </v-row>
        <v-divider />
      </div>
    </div>
  </v-card>
</template>

<script>
import widgetMixin from "../../../_helpers/widgetMixin";
import latestValueMixin from "../../../_helpers/latestValueMixin";
import Sparkline from "../../chart/Sparkline.vue";
import tagDataRepository from "../../../api/repositories/tagDataRepository";

export default {
  name: "LastValue",

  mixins: [widgetMixin, latestValueMixin],
  components: { sprkline: Sparkline },

  props: {
    widget: {
      default: undefined,
    },

    tags: {
      default: () => [],
    },
  },

  data() {
    return {
      slotsWithData: [],
      data: [],
      fakeData: {
        trend: Math.floor(Math.random() * 20) + 10,
        current: Math.floor(Math.random() * 20) + 10,
      },
    };
  },

  computed: {
    name: (ctx) => (slot) => {
      if (ctx.hideNodeInfo) return slot.key;

      if (ctx.shouldFake) return `Node Slot ${slot.index} - ${slot.key}`;

      return ctx.constructLabelFromTagAndKey(
        ctx.tags[slot.index - 1],
        slot.key
      );
    },

    date: (ctx) => (slot) => {
      if (ctx.shouldFake) return ctx.humanDate(new Date());

      let foundData = ctx.data.find(
        (d) => d.tagDeveui == ctx.tagDeveuiForSlot(slot) && d.key == slot.key
      );

      return ctx.humanDate(foundData?.createdAt) ?? "N/A";
    },

    slotValue: (ctx) => (slot) => {
      let prefix = "";
      if (ctx.prefix) prefix = ctx.prefix + " ";

      let sufix = "";
      if (ctx.sufix) sufix = " " + ctx.sufix;

      // Sets the prefix value and sufix
      return `${prefix}${ctx.val(slot)}${sufix}`;
    },

    val: (ctx) => (slot) => {
      let val = "N/A";
      if (ctx.shouldFake) return ctx.hasValueMatch(ctx.fakeData.current);

      var foundData = ctx.data.find(
        (d) => d.tagDeveui == ctx.tagDeveuiForSlot(slot) && d.key == slot.key
      );
      if (!foundData?.value) return "N/A";

      val = ctx.hasValueMatch(foundData.value);
      return +parseFloat(val)?.toFixed(2) ?? val; // TODO Meta for deciamal rounding
    },

    trendValue: (ctx) => (slot) => {
      if (ctx.shouldFake) return ctx.fakeData.trend;

      let foundData = ctx.data.filter(
        (d) => d.tagDeveui == ctx.tagDeveuiForSlot(slot) && d.key == slot.key
      );
      if (!foundData?.length < 1) return foundData[0] ?? undefined;

      return foundData[1] ?? undefined;
    },

    getTrendIcon: (ctx) => (slot) => {
      let trendVal = ctx.trendValue(slot);
      let currentVal = ctx.val(slot);

      if (trendVal === undefined && currentVal === undefined)
        return "mdi-trending-neutral";

      if (isNaN(parseFloat(trendVal)) || isNaN(parseFloat(currentVal)))
        return "mdi-trending-neutral";

      let flooredTrend = Math.floor(parseFloat(trendVal));
      let flooredCurrent = Math.floor(parseFloat(currentVal));
      if (flooredCurrent < flooredTrend) return "mdi-trending-down";
      else if (flooredCurrent > flooredTrend) return "mdi-trending-up";
      else if (flooredCurrent === flooredTrend) return "mdi-trending-neutral";
      else return "mdi-trending-neutral";
    },

    getTrendColor: (ctx) => (slot) => {
      let icon = ctx.getTrendIcon(slot);
      let color = "yellow";
      if (icon == "mdi-trending-up") color = "green";
      else if (icon == "mdi-trending-down") color = "red";
      return color;
    },

    sparklineData: (ctx) => (slot) => {
      // Fake the data
      if (ctx.shouldFake)
        return Array.from({ length: 5 }, () => {
          return { value: Math.floor(Math.random() * 20) + 10 };
        });

      return (
        ctx.data
          .filter(
            (d) =>
              d.tagDeveui == ctx.tagDeveuiForSlot(slot) && d.key == slot.key
          )
          .map((d) => {
            return {
              value: d.value,
            };
          }) ?? []
      );
    },
  },

  methods: {
    tagDeveuiForSlot(slot) {
      return this.tags[slot.index - 1] ?? "no-deveui";
    },

    getSlotsWithData() {
      if (this.shouldFake) return this.widgetSlots;

      let slots = [];
      for (var s of this.widgetSlots) {
        var foundData = this.data.find(
          (d) => d.tagDeveui == this.tagDeveuiForSlot(s) && d.key == s.key
        );
        if (!foundData?.value) continue;

        slots.push(s);
      }

      return slots;
    },

    constructSlotsWithData() {
      let slts = this.getSlotsWithData();
      let slotsCount = this.getMeta(this.metaKeys.SLOTS_COUNT)?.value;
      if (
        slotsCount === undefined ||
        isNaN(parseInt(slotsCount)) ||
        parseInt(slotsCount) > slts.length
      )
        this.slotsWithData = slts;
      else this.slotsWithData = slts.slice(0, parseInt(slotsCount));
    },
  },

  async created() {
    if (this.shouldFake) {
      this.constructSlotsWithData();
      return;
    }

    // Fetch latest 10 datapoints for each of the tags with keys and group them by date
    this.data =
      (
        await tagDataRepository.getTagdataWithLimit(
          this.getDeveuiAndKeysForSlots(this.tags),
          10
        )
      )?.data ?? [];

    this.constructSlotsWithData();
  },
};
</script>
