<template>
  <widget-container :widget="widget">
    <div
      :style="`max-height: 100%; max-width: 100%; height: 100%; ${
        hasTitle ? 'padding-bottom: 4rem' : ''
      }`"
    >
      <e-chart
        v-if="opts !== undefined && max !== undefined && min !== undefined"
        :option="opts"
        :style="`z-index: 1; width: 100%; height: ${
          hasOneEighty ? '100%;' : '100%;'
        } overflow: hidden`"
        :autoresize="true"
      />
      <div v-else-if="this.max === undefined || this.min === undefined">
        {{ $t("module.gauge.missingMaxMin") }}
      </div>
      <div v-else>
        {{ $t("module.noData") }}
      </div>
    </div>
  </widget-container>
</template>

<script>
import WidgetContainer from "./WidgetContainer.vue";
import widgetMixin from "../../../_helpers/widgetMixin";
import gaugeMixin from "../../../_helpers/gaugeMixin";
import tagDataRepository from "../../../api/repositories/tagDataRepository";

export default {
  name: "Gauge",

  props: {
    widget: {
      default: undefined,
    },

    tags: {
      default: () => [],
    },
  },

  components: { WidgetContainer },
  mixins: [widgetMixin, gaugeMixin],

  data() {
    return { opts: undefined };
  },

  computed: {},

  methods: {
    async setupOptions() {
      let base = this.baseOptions;
      let data = await this.fetchData();

      // Set target
      if (!this.hasTarget) {
        base.series.splice(0, 1);
        base.series[0].data[0].value = +parseFloat(data).toFixed(2);
        base.series[0] = this.setFormater(base.series[0]);
      } else {
        base.series[0].data[0].value = +parseFloat(this.target).toFixed(2);
        base.series[1].data[0].value = +parseFloat(data).toFixed(2);
        base.series[1] = this.setFormater(base.series[1]);
      }

      // Set max min for each series
      for (var series of base.series) {
        series.min = this.min;
        series.max = this.max;
      }

      // Check and setup 180 gauge
      if (this.hasOneEighty) base = this.set180(base);

      base = this.setColors(base);

      this.opts = base;
    },

    setFormater(series) {
      series.detail.formatter = (v) =>
        `${this.prefix}${+v.toFixed(2)}${this.sufix}`;
      return series;
    },

    setColors(options) {
      // Set text colors
      let sIdx = options.series.length > 1 ? 1 : 0;
      options.series[sIdx].axisTick.lineStyle.color =
        this.contentTextColor?.value ?? this.defaultTextColor;
      options.series[sIdx].splitLine.lineStyle.color =
        this.contentTextColor?.value ?? this.defaultTextColor;
      options.series[sIdx].axisTick.color =
        this.contentTextColor?.value ?? this.defaultTextColor;
      options.series[sIdx].axisLabel.color =
        this.contentTextColor?.value ?? this.defaultTextColor;
      options.series[sIdx].detail.color =
        this.contentTextColor?.value ?? this.defaultTextColor;

      let colors = [];
      let metaColors = this.getMetas(this.templateColorKeys.GAUGE_COLOR);
      for (var mc of metaColors) {
        colors.push([mc.content / 100, mc.value]);
      }

      if (colors.length === 0) return options;

      colors = colors.sort((a, b) => a[0] - b[0]);
      if (colors[colors.length - 1][0] < 1) colors[colors.length - 1][0] = 1;

      // Set the gauge color
      options.series[sIdx].axisLine.lineStyle.color = colors;

      return options;
    },

    async rawData() {
      if (this.shouldFake) return this.fakeData();

      // Get values for all tags and keys associated so we can fetch the slot to get prioritized data
      let dataValues =
        (
          await tagDataRepository.getCurrentsMultiTagAndKey(
            this.getDeveuiAndKeysForSlots(this.tags)
          )
        )?.data ?? [];

      // Get the prioritizedSlot to display
      let data = this.getSingleValueFromPrioritizedSlot(this.tags, dataValues);

      return data ?? "N/A";
    },

    async fetchData() {
      var result = await this.rawData();

      if (isNaN(result)) return result;
      return +parseFloat(result).toFixed(2);
    },

    fakeData() {
      return Math.round((Math.random() * this.max ?? 10) + this.min ?? 10);
    },
  },

  async created() {
    await this.setupOptions();
  },
};
</script>
