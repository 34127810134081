<template>
  <div class="base-container-action-chip" :style="containerStyle">
    <b>{{ $t("valueForm.target") }}</b>
    <b> {{ content?.op ?? "" }} </b>
    {{ content?.target ?? "N/A" }}
    <span v-if="content?.actionType === 'value'"
      ><b>=</b> {{ content?.content }}</span
    >

    <v-btn icon @click="() => $emit('remove')"
      ><v-icon>mdi-close</v-icon></v-btn
    >
  </div>
</template>

<script>
export default {
  name: "ActionForValueChip",

  props: {
    content: {
      default: () => {},
    },
  },

  computed: {
    containerStyle() {
      if (this.content?.actionType === "value") return "";

      let result = "";
      if (this.content?.colors?.background !== undefined)
        result += `background-color: ${this.content.colors.background};`;
      if (this.content?.colors?.border !== undefined)
        result += `border: 2px solid ${this.content.colors.border};`;
      if (this.content?.colors?.text !== undefined)
        result += `color: ${this.content.colors.text};`;

      return result;
    },
  },
};
</script>

<style>
.base-container-action-chip {
  border-radius: 10px;
  padding: 1rem;
  border: 2px solid black;
}
</style>