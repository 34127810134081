import { icon, divIcon } from "leaflet";
import { mapActions } from "vuex";

import widgetMixin from "./widgetMixin";
import iconMixin from "./iconMixin";

export default {
  mixins: [widgetMixin, iconMixin],

  data() {
    return { markers: [] };
  },

  computed: {
    minZoom() {
      return -2;
    },

    maxZoom() {
      return 3;
    },

    icon: () => {
      return icon({
        iconUrl: "/icons/Circle-icon.png",
        iconSize: [15, 15],
        iconAncor: [10, 10],
      });
    },

    icons() {
      return {
        default: this.svgMarkerIcon,
        blue: this.blueIcon,
      };
    },

    iconHasColor() {
      return {
        default: true,
        blue: false,
      };
    },

    iconKey: (ctx) => (markerMetaId) => {
      return (
        ctx.getAssociatedMeta(ctx.metaKeys.IMAGEMAP_ICON, markerMetaId)
          ?.contnet ?? "default"
      );
    },

    selectedIcon: (ctx) => (markerMetaId) => {
      if (ctx.iconKey(markerMetaId) === undefined)
        return ctx.icons.default(markerMetaId);

      let icon = ctx.icons[ctx.iconKey(markerMetaId)];
      return icon(markerMetaId) ?? ctx.icons.default(markerMetaId);
    },

    markerIcon: (ctx) => (markerMetaId) => {
      return ctx.selectedIcon(markerMetaId);
    },

    emptyIcon() {
      return icon({
        iconUrl: "/icons/emptyIcon.png",
        iconSize: [15, 15],
        iconAncor: [10, 10],
      });
    },

    svgMarkerIcon: (ctx) => (markerMetaId) => {
      let p = ctx.markerIconProportions(markerMetaId, {
        width: 45,
        height: 45,
        anchorX: 22,
        anchorY: 22,
      });

      let background =
        ctx.getAssociatedMeta(
          ctx.metaKeys.IMAGEMAP_ICON_COLOR_BACKGROUND,
          markerMetaId
        )?.content ?? "black";
      let border =
        ctx.getAssociatedMeta(
          ctx.metaKeys.IMAGEMAP_ICON_COLOR_BORDER,
          markerMetaId
        )?.content ?? "white";

      // Set the colors based on value
      let colorsFromValue = ctx.actionForValueResult(
        ctx.markerValue(markerMetaId),
        markerMetaId,
        (v) => v.content.actionType === "color"
      );

      if (colorsFromValue?.background !== undefined)
        background = colorsFromValue.background;
      if (colorsFromValue?.border !== undefined)
        border = colorsFromValue.border;

      return divIcon({
        html: ctx.roundBorderedIcon(p.width, p.height, background, border),
        iconSize: [p.width, p.height],
        iconAncor: [p.anchorX, p.anchorY],
      });
    },

    actionForValueResult:
      (ctx) =>
      (
        val,
        markerMetaId,
        metaFilter = () => {
          return true;
        }
      ) => {
        let metas = ctx.getAssociatedMetas(
          ctx.metaKeys.IMAGEMAP_ACTION_FOR_VALUE,
          markerMetaId,
          true,
          metaFilter
        );
        if (metas === undefined || metas.length <= 0) return undefined;

        for (var action of metas) {
          if (
            action?.content?.op === undefined ||
            action?.content?.actionType === undefined
          )
            continue;

          let exp = `${isNaN(parseFloat(val)) ? `"${val}"` : val}${
            action.content.op
          } ${
            isNaN(parseFloat(action.content.target))
              ? `"${action.content.target}"`
              : action.content.target
          }`;

          if (eval(exp))
            return action.content.actionType === "color"
              ? action.content.colors
              : action.content.content;
        }

        return undefined;
      },

    markerValue: (ctx) => (markerMetaId) => {
      let meta = ctx.getMetaById(markerMetaId);
      if (meta?.data === undefined) return "N/A";

      // TODOLO: Check if actionForValue has a match for the value change
      let actionForValue = ctx.actionForValueResult(
        meta.data,
        markerMetaId,
        (v) => v.content.actionType === "value"
      );
      if (actionForValue !== undefined) return actionForValue;

      return ctx.formatValue(meta.data, markerMetaId);
    },

    blueIcon: (ctx) => (markerMetaId) => {
      let p = ctx.markerIconProportions(markerMetaId, {
        width: 20,
        height: 20,
        anchorX: 10,
        anchorY: 10,
      });

      return icon({
        iconUrl: "/icons/Circle-icon.png",
        iconSize: [p.width, p.height],
        iconAncor: [p.anchorX, p.anchorY],
      });
    },

    tooltipStyle: (ctx) => (markerMetaId) => {
      let fontSize = ctx.getAssociatedMeta(
        ctx.metaKeys.IMAGEMAP_FONTSTYLE,
        markerMetaId
      )?.content;

      let fontColor = ctx.getAssociatedMeta(
        ctx.metaKeys.IMAGEMAP_ICON_COLOR_TEXT,
        markerMetaId
      )?.content;

      let colorsFromValue = ctx.actionForValueResult(
        ctx.markerValue(markerMetaId),
        markerMetaId,
        (v) => v.content.actionType === "color"
      );
      if (colorsFromValue?.text !== undefined) fontColor = colorsFromValue.text;

      return `font-size: ${fontSize ?? 14}px; font-weight: bolder; color: ${
        fontColor ?? "white"
      };`;
    },
  },

  methods: {
    ...mapActions("tagData", ["getCurrentTagdata"]),

    setMarkers() {
      let nodes = this.getMetas(this.metaKeys.IMAGEMAP_MARKERS);
      if (nodes === undefined) return [];

      let markers = [];
      for (var n of nodes) {
        if (n.content === undefined || n.content.length <= 0) continue;

        markers.push({
          metaId: n.templateWidgetMetaId,
          latlng: JSON.parse(n.content),
        });
      }

      this.markers = markers;
      this.syncData();
    },

    async syncData() {
      for (var marker of this.markers) {
        // Get deveui and key from meta
        let deveui = this.getAssociatedMeta(
          this.metaKeys.IMAGEMAP_NODE,
          marker.metaId
        )?.content;
        let key = this.getAssociatedMeta(
          this.metaKeys.IMAGEMAP_NODE_KEY,
          marker.metaId
        )?.content;
        let mMeta = this.getMetaById(marker.metaId);
        if (deveui === undefined || key === undefined || mMeta === undefined)
          continue;

        // Fetch latest value for device
        let res = await this.getCurrentTagdata({ tagId: deveui, tagKey: key });
        mMeta.data = res?.value;
      }
      this._updateMarkers();
    },

    formatValue(val, markerMetaId) {
      if (val === undefined) return undefined;

      let prefix =
        this.getAssociatedMeta(this.metaKeys.PREFIX, markerMetaId)?.content ??
        "";
      let sufix =
        this.getAssociatedMeta(this.metaKeys.SUFIX, markerMetaId)?.content ??
        "";

      // TODO: Apply formula

      return `${prefix} ${val} ${sufix}`.trim();
    },

    getImageMeta(url) {
      if (url == undefined || url == "") return;
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = url;
      });
    },

    markerIconProportions(markerMetaId, defaults) {
      if (markerMetaId === undefined || markerMetaId === "")
        return (
          defaults ?? {
            width: 15,
            height: 15,
            anchorX: 10,
            anchorY: 10,
          }
        );

      return {
        width:
          this.getAssociatedMeta(
            this.metaKeys.IMAGEMAP_ICON_WIDTH,
            markerMetaId
          )?.content ??
          defaults?.width ??
          15,
        height:
          this.getAssociatedMeta(
            this.metaKeys.IMAGEMAP_ICON_HEIGHT,
            markerMetaId
          )?.content ??
          defaults?.height ??
          15,
        anchorX:
          this.getAssociatedMeta(
            this.metaKeys.IMAGEMAP_ICON_ANCHOR_X,
            markerMetaId
          )?.content ??
          defaults?.anchorX ??
          10,
        anchorY:
          this.getAssociatedMeta(
            this.metaKeys.IMAGEMAP_ICON_ANCHOR_Y,
            markerMetaId
          )?.content ??
          defaults?.anchorY ??
          10,
      };
    },
  },
};
