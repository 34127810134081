<template>
  <v-card
    :style="`width: 100% !important; height: 100% !important; overflow: hidden; max-height: 100%; ${contentStyle}`"
  >
    <v-card-text style="max-height: 90%; overflow-y: scroll">
      <v-data-table
        :headers="headers"
        :items="items"
        class="weathertable hide-scrollbar"
        style="background: transparent"
        dense
        :search="search"
        :items-per-page="items.length"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ humanDate(item.createdAt) }}
        </template>

        <template v-slot:[`item.tagDeveui`]="{ item }">
          {{ item?.tag?.name ?? item.tagDeveui }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import tagDataRepository from "../../../api/repositories/tagDataRepository";
import widgetMixin from "../../../_helpers/widgetMixin";

export default {
  name: "RawTable",

  mixins: [widgetMixin],

  props: {
    widget: {
      default: undefined,
    },

    tags: {
      default: () => [],
    },
  },

  data() {
    return {
      headers: [
        { text: this.$t("tagdata.fields.tagDeveui"), value: "tagDeveui" },
        { text: this.$t("tagdata.fields.key"), value: "label" },
        { text: this.$t("tagdata.fields.value"), value: "value" },
        { text: this.$t("tagdata.fields.createdAt"), value: "createdAt" },
      ],

      items: [],
      search: "", // NOTE: Serach is not currently used but will be used in future
    };
  },

  computed: {
    slots() {
      return this.widget?.slots;
    },
  },

  methods: {
    // Function to display data while creating information
    // This should generate the data for _ALL_ slots
    generateFakeData() {
      if (this.widget?.slots === undefined || this.widget?.slots.length <= 0)
        return [];

      let result = [];
      const fluc = [1, 0.7, 1, 1.2, 1, 1.2, 0.7, 0.9];
      for (var slot of this.widget.slots) {
        let tempDate = new Date().zeroTimeFromMinutes().addHours(-25);
        for (var i = 0; i < 24; ++i) {
          tempDate = tempDate.addHours(1);

          result.push({
            tagDeveui: slot.slot,
            label: slot.key, // TODO: Change this to use the Label instead of the key
            value: (
              1.3 *
              fluc[Math.floor(Math.random() * (10 - 0 + 1) + 0) % fluc.length]
            ).toFixed(2),
            createdAt: new Date(tempDate),
          });
        }
      }

      result.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      return result;
    },

    async fetchData() {
      // Get values for all tags and keys associated so we can fetch the slot to get prioritized data
      let dataValues =
        (
          await tagDataRepository.getTagdataWithLimit(
            this.getDeveuiAndKeysForSlots(this.tags),
            Math.ceil(200 / this.slots.length)
          )
        )?.data ?? [];

      // Map the value to fixed 4 decimals max
      // This is done here to keep the searchability of the value in datatable
      this.items = dataValues.map((d) => {
        return {
          ...d,
          value: isNaN(parseFloat(d.value))
            ? d.value
            : +parseFloat(d.value)?.toFixed(4),
        };
      });
    },
  },

  async created() {
    if (this.shouldFake) {
      this.items = this.generateFakeData();
      return;
    }

    // Fetch the data for the slots if there should not be faked
    await this.fetchData();
  },
};
</script>