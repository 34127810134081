<template>
  <v-expansion-panels v-if="id !== undefined">
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ $t("imageMap.nodeSelection") }}
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <div class="d-flex justify-space-between align-center mb-3">
          <v-autocomplete
            :value="getAssociatedMeta(metaKeys.IMAGEMAP_NODE, id)?.content"
            @input="
              (v) => {
                addAssociatedMeta(metaKeys.IMAGEMAP_NODE, v, id);
                $emit('update-markers');
              }
            "
            :items="tags"
            :label="$t('tag.picker.step1')"
            item-text="name"
            item-value="deveui"
            prepend-icon="mdi-lock-open"
            @change="fetchKeysForTag"
          />

          <v-autocomplete
            :value="getAssociatedMeta(metaKeys.IMAGEMAP_NODE_KEY, id)?.content"
            @input="
              (v) => {
                addAssociatedMeta(metaKeys.IMAGEMAP_NODE_KEY, v, id);
                $emit('update-markers');
              }
            "
            :items="keys"
            :label="$t('tag.picker.step2')"
            item-text="label"
            item-value="key"
            prepend-icon="mdi-key"
          />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ $t("imageMap.iconFormTitle") }}
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <div class="d-flex justify-space-between align-center mb-3">
          <v-slider
            :value="
              getAssociatedMeta(metaKeys.IMAGEMAP_ICON_WIDTH, id)?.content ?? 45
            "
            @input="
              (v) => {
                addAssociatedMeta(metaKeys.IMAGEMAP_ICON_WIDTH, v, id);
                $emit('update-markers');
              }
            "
            thumb-label="always"
            :label="$t('imageMap.icon.width')"
          />
          <v-slider
            :value="
              getAssociatedMeta(metaKeys.IMAGEMAP_ICON_HEIGHT, id)?.content ??
              45
            "
            @input="
              (v) => {
                addAssociatedMeta(metaKeys.IMAGEMAP_ICON_HEIGHT, v, id);
                $emit('update-markers');
              }
            "
            thumb-label="always"
            :label="$t('imageMap.icon.height')"
          />
        </div>
        <!-- <div class="d-flex justify-space-between align-center">
          <v-slider
            :value="
              getAssociatedMeta(metaKeys.IMAGEMAP_ICON_ANCHOR_X, id)?.content ??
              22
            "
            @input="
              (v) => {
                addAssociatedMeta(metaKeys.IMAGEMAP_ICON_ANCHOR_X, v, id);
                $emit('update-markers');
              }
            "
            thumb-label="always"
            :label="$t('imageMap.icon.anchorx')"
          />
          <v-slider
            :value="
              getAssociatedMeta(metaKeys.IMAGEMAP_ICON_ANCHOR_Y, id)?.content ??
              22
            "
            @input="
              (v) => {
                addAssociatedMeta(metaKeys.IMAGEMAP_ICON_ANCHOR_Y, v, id);
                $emit('update-markers');
              }
            "
            thumb-label="always"
            :label="$t('imageMap.icon.anchory')"
          />
        </div> -->

        <widget-image-map-font-selector
          :value="
            getAssociatedMeta(metaKeys.IMAGEMAP_FONTSTYLE, id)?.content ?? 14
          "
          @input="
            (v) => {
              addAssociatedMeta(metaKeys.IMAGEMAP_FONTSTYLE, v, id);
              $emit('update-markers');
            }
          "
        />

        <div class="d-flex flex-wrap justify-space-between align-center py-2">
          <color-picker
            v-if="iconHasColor[iconKey(id)] ?? false"
            :label="$t('imageMap.colors.background')"
            :value="
              getAssociatedMeta(metaKeys.IMAGEMAP_ICON_COLOR_BACKGROUND, id)
                ?.content
            "
            @input="
              (v) => {
                addAssociatedMeta(
                  metaKeys.IMAGEMAP_ICON_COLOR_BACKGROUND,
                  v,
                  id
                );
                $emit('update-markers');
              }
            "
          />
          <color-picker
            v-if="iconHasColor[iconKey(id)] ?? false"
            :label="$t('imageMap.colors.border')"
            :value="
              getAssociatedMeta(metaKeys.IMAGEMAP_ICON_COLOR_BORDER, id)
                ?.content
            "
            @input="
              (v) => {
                addAssociatedMeta(metaKeys.IMAGEMAP_ICON_COLOR_BORDER, v, id);
                $emit('update-markers');
              }
            "
          />

          <color-picker
            :label="$t('imageMap.colors.text')"
            :value="
              getAssociatedMeta(metaKeys.IMAGEMAP_ICON_COLOR_TEXT, id)?.content
            "
            @input="
              (v) => {
                addAssociatedMeta(metaKeys.IMAGEMAP_ICON_COLOR_TEXT, v, id);
                $emit('update-markers');
              }
            "
          />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ $t("imageMap.valueFormPanel") }}
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <action-for-value
          @input="
            (v) => {
              addAssociatedMeta(
                this.metaKeys.IMAGEMAP_ACTION_FOR_VALUE,
                v,
                id,
                false,
                true
              );
            }
          "
        />
        <div class="d-flex flex-wrap justify-start align-center my-2">
          <action-for-value-chip
            class="ma-1"
            v-for="item in getAssociatedMetas(
              this.metaKeys.IMAGEMAP_ACTION_FOR_VALUE,
              id,
              true
            )"
            :key="item.templateWidgetMetaId"
            :content="item.content"
            @remove="removeMeta(item.templateWidgetMetaId)"
          />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import imageMapMixin from "../../../../_helpers/imageMapMixin";
import widgetMixin from "../../../../_helpers/widgetMixin";

import WidgetImageMapFontSelector from "./WidgetImageMapFontSelector.vue";
import ColorPicker from "../../customForm/ColorPicker.vue";
import ActionForValue from "../../customForm/ActionForValue.vue";
import ActionForValueChip from "../../customForm/ActionForValueChip.vue";

import { mapState, mapActions } from "vuex";

export default {
  name: "WidgetImageMapMarkerForm",

  components: {
    WidgetImageMapFontSelector,
    "color-picker": ColorPicker,
    ActionForValue,
    ActionForValueChip,
  },

  props: {
    id: {
      default: undefined,
    },

    mdlWidget: {
      default: undefined,
    },
  },

  mixins: [widgetMixin, imageMapMixin],

  model: {
    prop: "mdlWidget",
  },

  data() {
    return {
      ic: {
        width: 15,
        height: 15,
        anchorX: 10,
        anchorY: 10,
      },
    };
  },

  methods: {
    ...mapActions("tag", ["getTags", "getKeysForTag"]),

    async fetchKeysForTag() {
      let deveui = this.getAssociatedMeta(
        this.metaKeys.IMAGEMAP_NODE,
        this.id
      )?.content;
      if (deveui === undefined) return [];

      await this.getKeysForTag({ tagId: deveui });
    },
  },

  computed: {
    ...mapState("tag", ["tags"]),
    ...mapState("tagData", ["keys", "status"]),

    widget: {
      get() {
        return this.mdlWidget;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  async created() {
    await this.getTags();
    await this.fetchKeysForTag();
  },
};
</script>