<template>
  <v-card
    :style="`width: 100% !important; height: 100% !important; overflow: hidden; max-height: 100%; ${contentStyle}`"
  >
    <v-toolbar
      dense
      flat
      :style="titleStyle"
      v-if="widget?.title !== undefined && widget?.title !== ''"
    >
      <v-toolbar-title>{{ widget.title }}</v-toolbar-title>
    </v-toolbar>

    <v-card-text style="height: 100%">
      <e-chart
        v-if="opts?.series !== undefined && opts.series.length > 0"
        :option="opts"
        style="width: 100%; max-width: 100%; min-height: 100%; max-height: 100%"
        class="pb-3"
        :autoresize="true"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/dataZoom";
import "echarts/lib/component/markLine";
import "echarts/lib/chart/line";
import "echarts/lib/coord/cartesian/Grid";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/scatter";

import { fillLevelMixin } from "../../../_helpers/fillLevelMixin";
import tagDataRepository from "../../../api/repositories/tagDataRepository";

export default {
  name: "FillLevel",

  mixins: [fillLevelMixin],

  props: {
    widget: {
      default: undefined,
    },

    tags: {
      default: () => [],
    },
  },

  data() {
    return {
      opts: undefined,
      selectedSlot: undefined,
    };
  },

  computed: {},

  methods: {
    async fetchData() {
      // Get values for all tags and keys associated so we can fetch the slot to get prioritized data
      let dataValues =
        (
          await tagDataRepository.getCurrentsMultiTagAndKey(
            this.getDeveuiAndKeysForSlots(this.tags)
          )
        )?.data ?? [];

      this.selectedSlot = this.getPrioritizedSlot(this.tags, dataValues);

      // Get the prioritizedSlot to display
      return this.getSingleValueFromPrioritizedSlot(this.tags, dataValues);
    },
  },

  async created() {
    if (this.shouldFake) return this.setFakeData();

    var data = (await this.fetchData()) ?? 0;

    if (!isNaN(parseFloat(data))) data = +parseFloat(data).toFixed(2);

    var label = "";
    if (this.selectedSlot)
      label = this.constructLabelFromTagAndKey(
        this.tags[this.selectedSlot.index - 1],
        this.selectedSlot.key
      );

    this.setData(data, label);
  },
};
</script>