import i18n from "@/plugins/i18n";
import Meta from "./ModuleMeta";
import widgetMixin from "./widgetMixin";

export const chartMixin = {
  mixins: [widgetMixin],
  computed: {
    metaKeys() {
      return Meta.Enum;
    },

    chartType: (ctx) => (slotId) => {
      var chartTypeMeta = ctx.getMeta(ctx.metaKeys.CHART_TYPE, slotId);
      if (chartTypeMeta === undefined) return "area";

      return chartTypeMeta.value;
    },

    chartTypes() {
      return [
        {
          name: this.$t("module.chart.types.area"),
          value: "area",
          icon: "mdi-chart-areaspline-variant",
        },
        {
          name: this.$t("module.chart.types.line"),
          value: "line",
          icon: "mdi-chart-areaspline",
        },
        {
          name: this.$t("module.chart.types.bar"),
          value: "bar",
          icon: "mdi-chart-bar",
        },
        {
          name: this.$t("module.chart.types.scatter"),
          value: "scatter",
          icon: "mdi-chart-scatter-plot",
        },
        {
          name: this.$t("module.chart.types.trend"),
          value: "trend",
          icon: "mdi-chart-bar-stacked",
        },
      ];
    },

    meta() {
      return this?.widget?.meta;
    },

    base() {
      return {
        legend: {
          show: true,
          data: [],
        },

        grid: {
          top: 55,
          bottom: 50,
          left: 80,
          right: 80,
        },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },

        xAxis: {
          type: "time",
          splitLine: {
            show: false,
          },
          axisLabel: {},
          axisLine: { lineStyle: {} },
        },

        yAxis: [],

        dataZoom: [{ show: true, type: "inside", throttle: 50 }],

        series: [],
      };
    },

    baseSeriesLine: () => (slotId, name) => {
      return {
        type: "line",
        slotId: slotId,
        name: name,
        data: [],
        areaStyle: undefined,
        axisLabel: {
          color: "#999",
        },
        itemStyle: {},
        lineStyle: {},
        step: false,
        markLine: { data: [] },
      };
    },

    baseSeriesScatter: () => (slotId, name) => {
      return {
        type: "scatter",
        slotId: slotId,
        name: name,
        data: [],
        areaStyle: undefined,
        itemStyle: {},
        lineStyle: {},
        axisLabel: {
          color: "#999",
        },
        step: false,
        markLine: { data: [] },
      };
    },

    baseSeriesArea: () => (slotId, name) => {
      return {
        type: "line",
        slotId: slotId,
        name: name,
        data: [],
        areaStyle: { opacity: 0.15 },
        itemStyle: {},
        lineStyle: {},
        axisLabel: {
          color: "#999",
        },
        step: false,
        markLine: { data: [] },
      };
    },

    baseSeriesBar: () => (slotId, name) => {
      return {
        type: "bar",
        slotId: slotId,
        name: name,
        data: [],
        areaStyle: { opacity: 0.35 },
        itemStyle: {},
        lineStyle: {},
        axisLabel: {
          color: "#999",
        },
        step: false,
        markLine: { data: [] },
      };
    },

    yAxisBaseDefault: () => (name, min, max) => {
      return {
        default: true,
        type: "value",
        name: name,
        min: min,
        max: max,
        axisLabel: {
          color: "#999",
        },
        axisLine: {
          show: true,
          lineStyle: {
            width: 2,
          },
        },
      };
    },

    yAxisBaseSync: () => (slotId, name, min, max, len) => {
      return {
        type: "value",
        slotId: slotId,
        name: name,
        position: len > 0 ? "right" : "left",
        offset: len > 1 ? (len - 1) * 70 : 4,
        nameLocation: (len - 1) % 2 == 1 ? "start" : "end",
        min: min,
        max: max,
        splitLine: { show: false },
        axisLine: {
          show: true,
          lineStyle: {
            width: 2,
          },
        },
        axisLabel: {
          show: true,
        },
      };
    },

    metaCount: (ctx) => (key) => {
      if (ctx.meta === undefined) return 0;

      return ctx.meta.filter((d) => d.key === key).length;
    },

    defaultFromDate() {
      return new Date().addHours(-12);
    },
  },

  methods: {
    getFromDateOrDefault() {
      const timeSpan = this.getMeta(this.metaKeys.DEFAULT_TIMESPAN);
      if (timeSpan == undefined) return this.defaultFromDate;
      switch (timeSpan.value) {
        case "hour":
          return new Date().addHours(-1);
        case "5_hour":
          return new Date().addHours(-5);
        case "24_hour":
          return new Date().addHours(-24);
        case "7_days":
          return new Date().addDays(-7);
        case "30_days":
          return new Date().addDays(-30);
        default:
          return this.defaultFromDate;
      }
    },

    verticalAxis(options) {
      let temp = options.xAxis;
      options.xAxis = options.yAxis;
      options.yAxis = temp;

      let i = 0;
      for (var ax of options.xAxis) {
        ax.nameLocation = "end";
        ax.nameGap = 10;
        ax.axisLine = { show: true, onZero: false, margin: 4 };
        ax.offset = 25 * i;
        i++;
      }

      // Remove all axises that are not default
      for (var ser of options.series) {
        ser.xAxisIndex = ser.yAxisIndex;
        ser.yAxisIndex = undefined;
        ser.nameLocation = "end";
      }

      return options;
    },

    addLegend(opts, name) {
      if (opts === undefined || opts.legend === undefined) return;

      if (!opts.legend.data.includes(name)) opts.legend.data.push(name);
    },

    setAreaColor(series, color) {
      let areaStyle = { opacity: 0.35 };
      if (series?.areaStyle === undefined)
        return (series.areaStyle = {
          ...areaStyle,
          color: color,
        });

      series.areaStyle.color = color;
      return series;
    },

    generateFakeData(base, vertical) {
      var data = [];
      var date = new Date().zeroTimeFromMinutes().addHours(-25);
      const fluc = [1, 0.7, 1, 1.2, 1, 1.2, 0.7, 0.9];
      for (var i = 0; i < 24; ++i) {
        var value =
          base *
          fluc[Math.floor(Math.random() * (10 - 0 + 1) + 0) % fluc.length];
        date = date.addHours(1);

        data.push({
          name: date.toString(),
          value: vertical ? [value, new Date(date)] : [new Date(date), value],
        });
      }

      return data;
    },

    setTargetSeries(base, value, vertical = false) {
      var targetSeries = {
        type: "line",
        data: [],
        itemStyle: { color: "#33691e" },
        z: 9998,
        markLine: {
          data: vertical ? [{ xAxis: value }] : [{ yAxis: value }],
          silent: true,
          lineStyle: { width: 3 },
          symbolSize: 10,
          symbol: ["arrow", "arrow"],
          label: {
            fontWeight: "bold",
            position: "middle",
            formatter: "{c} | " + i18n.t("module.meta.fields.target"),
          },
        },
      };

      base.series.push(targetSeries);

      return base;
    },
  },
};
