<template>
  <v-card :style="`height: 100%; z-index: 0; ${contentStyle}`">
    <v-card-title v-if="hasTitle" :style="titleStyle">{{
      widget.title
    }}</v-card-title>
    <slot></slot>
  </v-card>
</template>

<script>
import widgetMixin from "../../../_helpers/widgetMixin";

export default {
  name: "WidgetContainer",

  props: {
    widget: { default: undefined },
  },

  mixins: [widgetMixin],
};
</script>