<template>
  <v-card
    no-gutter
    :style="`width: 100% !important; height: 100% !important; overflow: hidden; ${contentStyle}`"
  >
    <v-toolbar
      dense
      flat
      style="background: transparent"
      v-if="hasTitle"
      :style="titleStyle"
    >
      <v-toolbar-title>
        {{ widget.title }}
      </v-toolbar-title>
    </v-toolbar>

    <div class="d-flex justify-center align-center">
      <v-img
        v-if="imageUrl"
        :src="imageUrl"
        contain
        max-height="100%"
        style="overflow: hidden; max-height: 100%; max-width: 100%"
      />
    </div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import widgetMixin from "../../../_helpers/widgetMixin";

export default {
  name: "WidgetImage",

  mixins: [widgetMixin],

  props: {
    widget: {
      default: undefined,
    },
  },

  computed: {
    ...mapState("configuration", ["SO_API_BASE_URL"]),

    imageUrl() {
      return this.widget?.imagePath !== undefined
        ? `${this.SO_API_BASE_URL}/api/image/${this.widget?.imagePath}`
        : "missing-img.png";
    },
  },
};
</script>