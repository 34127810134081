import widgetMixin from "./widgetMixin";

export default {
  mixins: [widgetMixin],

  computed: {
    prefix() {
      let prefix = this.getMeta(this.metaKeys.PREFIX)?.value;

      return prefix ?? "";
    },

    sufix() {
      let sufix = this.getMeta(this.metaKeys.SUFIX)?.value;

      return sufix ?? "";
    },

    hasOneEighty() {
      let oneEighty = this.getMetaValueAsBool(this.metaKeys.GAUGE180);

      return oneEighty ?? false;
    },

    max() {
      let max = this.getMeta(this.metaKeys.MAX)?.value;
      return max;
    },

    min() {
      let min = this.getMeta(this.metaKeys.MIN)?.value;
      return min;
    },

    target() {
      let target = this.getMeta(this.metaKeys.TARGET);
      if (target === undefined) return 0;

      return target.content;
    },

    hasTarget() {
      let target = this.getMeta(this.metaKeys.TARGET);
      if (target === undefined) return false;

      return true;
    },

    baseOptions() {
      return {
        series: [
          {
            type: "gauge",
            center: ["50%", "50%"],
            min: 0,
            max: 60,
            z: 9999,
            itemStyle: {
              color: "#4169E1",
            },
            progress: {
              show: true,
              width: 5,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {},
            },
            axisTick: {
              show: false,
              lineStyle: {},
            },
            splitLine: {
              show: false,
              lineStyle: {},
            },
            axisLabel: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 0,
              },
            ],
          },
          {
            type: "gauge",
            min: 0,
            max: 60,
            center: ["50%", "50%"],

            axisLine: {
              lineStyle: {
                width: 15,
                color: [[1, "#6a995e"]],
              },
            },
            pointer: {
              show: true,
              itemStyle: {
                color: "inherit",
              },
            },
            axisTick: {
              show: true,
              splitNumber: 5,
              lineStyle: {
                width: 1,
              },
            },
            splitLine: {
              length: 10,
              lineStyle: {
                width: 3,
              },
            },
            axisLabel: {
              fontSize: 12,
              distance: 30,
              formatter: function (v) {
                return +v.toFixed(2);
              },
            },
            detail: {
              show: true,
              valueAnimation: true,
              width: "60%",
              lineHeight: 40,
              height: "15%",
              borderRadius: 8,
              offsetCenter: [0, "60%"],
              fontSize: 25,
              fontWeight: "bold",
              formatter: "{value}",
              color: "inherit",
            },
            data: [
              {
                value: 0,
              },
            ],
          },
        ],
      };
    },
  },

  methods: {
    set180(options) {
      if (options.series.length <= 0) {
        console.error("Could not find series for 180 gauge");
        return options;
      }

      for (var series of options.series) {
        series.startAngle = 180;
        series.endAngle = 0;
        series.detail.offsetCenter = [0, "30%"];
        series.axisTick.distance = 3;
        series.axisTick.lineStyle.color = "auto";
        series.splitLine.distance = 3;
        series.splitLine.lineStyle.color = "auto";
        series.axisLabel.distance = 17;
        series.axisLabel.color = "inherit";
        series.radius = "100%";
        series.axisLine.lineStyle.width = 15;
        series.center = ["50%", "70%"];
      }

      return options;
    },
  },
};
