var render = function render(){var _vm=this,_c=_vm._self._c;return _c('widget-container',{attrs:{"widget":_vm.widget}},[_c('l-map',{ref:"map",staticClass:"imagemap-container",attrs:{"min-zoom":_vm.minZoom,"max-zoom":_vm.maxZoom,"zoom":_vm.zoom,"crs":_vm.crs},on:{"click":_vm.clicked,"update:zoom":_vm.zoomed,"ready":_vm.updateLayout}},[(_vm.imageUrl !== undefined)?_c('l-image-overlay',{ref:"iOverlay",attrs:{"url":_vm.imageUrl,"bounds":_vm.bounds}}):_vm._e(),_c('div',{key:_vm.reactivity},[_vm._l((_vm.markers),function(item,i){return _c('l-marker',{key:i,attrs:{"lat-lng":item.latlng,"icon":_vm.markerIcon(item.metaId)},on:{"click":function($event){return _vm.markerClick(item)}}},[_c('l-tooltip',{key:item.data + item.metaId,attrs:{"options":{
            permanent: true,
            reactive: true,
            direction: 'center',
            opacity: 1,
          }}},[_c('div',{style:(_vm.tooltipStyle(item.metaId))},[_vm._v(" "+_vm._s(_vm.markerValue(item.metaId))+" ")])])],1)}),_c('v-dialog',{attrs:{"max-width":"700"},model:{value:(_vm.infoDialog),callback:function ($$v) {_vm.infoDialog=$$v},expression:"infoDialog"}},[_c('v-toolbar',{staticClass:"mb-0",attrs:{"dark":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":() => {
                _vm.infoDialog = false;
                _vm.selectedMeta = undefined;
              }}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.selectedMeta)?_c('widget-image-map-dialog-content',{attrs:{"meta-id":_vm.selectedMeta,"widget":_vm.widget}}):_vm._e()],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }