<template>
  <widget-container :widget="widget">
    <v-row
      no-gutters
      :style="`height: 100%; ${
        hasTitle ? 'padding-bottom: 3rem;' : 'padding-top: 1rem;'
      }`"
    >
      <e-chart
        v-if="opts !== undefined && opts.series.length > 0"
        :option="opts"
        style="width: 100%; max-width: 100%; min-height: 100%; max-height: 100%"
        :autoresize="true"
      />
      <h3 v-else style="height: 100%; text-align: center">Not enough data</h3>
    </v-row>
  </widget-container>
</template>

<script>
import WidgetContainer from "./WidgetContainer.vue";
import "echarts/lib/coord/cartesian/Grid";
import { chartMixin } from "../../../_helpers/chartMixin";
import widgetMixin from "../../../_helpers/widgetMixin";
import tagDataRepository from "../../../api/repositories/tagDataRepository";
import MetricHelper from "@/_helpers/metricHelper.js";
import { mapState } from "vuex";

export default {
  name: "TemplateChart",

  props: {
    widget: {
      default: undefined,
    },

    tags: {
      default: () => [],
    },
  },

  components: { WidgetContainer },

  mixins: [chartMixin, widgetMixin],

  data() {
    return {
      opts: undefined,
      seriesData: {},
    };
  },

  computed: {
    ...mapState("tag", ["tagsWithMeta"]),

    cmpTextColor() {
      return this.contentTextColor?.value ?? this.defaultTextColor;
    },
  },

  methods: {
    getSeriesName(slot) {
      let slotName = `Node Slot ${slot.index}`;
      if (this.shouldFake || this.tags.length < slot.index - 1)
        return `${slotName} - ${slot.key}`;

      let tag = this.tags[slot.index - 1];
      if (tag === undefined || this.tagsWithMeta[tag] === undefined)
        return `${slotName} - ${slot.key}`;

      return this.constructLabelFromTagAndKey(tag, slot.key);
    },

    setupOptions() {
      // Fetch the meta that are associated with this chart
      if (this.widget.slots === undefined) return undefined;

      let base = this.base;
      const isVertical = this.getMetaValueAsBool(this.metaKeys.VERTICAL);
      for (var slt of this.widget.slots) {
        if (
          this.seriesData[slt.slotId] === undefined ||
          this.seriesData[slt.slotId].length <= 0
        )
          continue;

        let type = this.chartType(slt.slotId);
        let name = this.getSeriesName(slt);
        switch (type) {
          case "line":
            this.addLegend(base, name);
            base.series.push(this.baseSeriesLine(slt.slotId, name));
            break;
          case "area":
            this.addLegend(base, name);
            base.series.push(this.baseSeriesArea(slt.slotId, name));
            break;
          case "bar":
            this.addLegend(base, name);
            base.series.push(this.baseSeriesBar(slt.slotId, name));
            break;
          case "vertical":
            this.addLegend(base, name);
            base.series.push(this.baseSeriesArea(slt.slotId, name));
            break;
          case "scatter":
            this.addLegend(base, name);
            base.series.push(this.baseSeriesScatter(slt.slotId, name));
            break;
          case "trend":
            this.addLegend(base, name);
            base.series.push(this.baseSeriesBar(slt.slotId, name));
            break;
          default:
            this.addLegend(base, name);
            base.series.push(this.baseSeriesArea(slt.slotId, name));
            break;
        }

        // Check if the series should have its own axis with the so_own_axis meta
        // If this is not true we use the default axis SYNC_Y is no longer valid
        const ownAxisMeta = this.getMeta(this.metaKeys.OWN_AXIS, slt.slotId);
        const axisLabel = this.getMeta(this.metaKeys.AXIS_LABEL, slt.slotId);
        const maxMeta = this.getMeta(this.metaKeys.MAX, slt.slotId);
        const minMeta = this.getMeta(this.metaKeys.MIN, slt.slotId);
        if (
          ownAxisMeta !== undefined &&
          (ownAxisMeta.value === "true" || ownAxisMeta.value === true)
        ) {
          // Validate that yAxis should actually be pushed or if the yAxis already has the
          // name we should not do anything
          if (base.yAxis === undefined || base.yAxis.length <= 0)
            base.yAxis = [];

          // Find if there already a yAxis with the id of the slot
          if (base.yAxis.map((d) => d.slotId).includes(slt.slotId)) return;

          base.yAxis.push(
            this.yAxisBaseSync(
              slt.slotId,
              axisLabel === undefined
                ? name.split(" - ").pop()
                : axisLabel.value,
              minMeta === undefined ? undefined : parseFloat(minMeta.value),
              maxMeta === undefined ? undefined : parseFloat(maxMeta.value),
              base.yAxis.length
            )
          );

          // Make space for new scale
          if (isVertical) {
            base.grid.top += 10 * base.yAxis.length;
            base.grid.right = 110;
          } else {
            base.grid.right += 10 * base.yAxis.length;
          }
          base.series[base.series.length - 1].yAxisIndex =
            base.yAxis.length - 1;
        } else {
          // Find the default y-axis
          let hasDefault = base.yAxis.findIndex((d) => d.default === true);
          if (hasDefault === -1) {
            base.yAxis.push(
              this.yAxisBaseDefault(
                axisLabel === undefined
                  ? name.split(" - ").pop()
                  : axisLabel.value,
                minMeta === undefined ? undefined : parseFloat(minMeta.value),
                maxMeta === undefined ? undefined : parseFloat(maxMeta.value)
              )
            );

            base.series[base.series.length - 1].yAxisIndex =
              base.yAxis.length - 1;
          } else {
            let baseYAxis = base.yAxis[hasDefault];
            baseYAxis.name +=
              ", " +
              (axisLabel === undefined
                ? name.split(" - ").pop()
                : axisLabel.value);
            base.series[base.series.length - 1].yAxisIndex = hasDefault;
          }
        }
      }

      // Set the colors for each series
      base = this.setColors(base);

      if (isVertical) base = this.verticalAxis(base);

      let target = this.getMeta(this.metaKeys.TARGET);
      if (
        target !== undefined &&
        target.content != null &&
        target.content !== ""
      )
        base = this.setTargetSeries(base, target.content, isVertical);

      this.opts = base;

      this.connectDataToSeries();
    },

    autoSetMaxMin(slotId) {
      let series = this.opts.series.find((d) => d.slotId === slotId);
      if (series === undefined) return;

      var axis = undefined;
      const isVertical = this.getMetaValueAsBool(this.metaKeys.VERTICAL);
      if (!isVertical) axis = this.opts.yAxis.find((d) => d.slotId === slotId);
      else axis = this.opts.xAxis.find((d) => d.slotId === slotId);

      if (axis === undefined && !isVertical)
        axis = this.opts.yAxis.find((d) => d.default);
      else if (axis === undefined)
        axis = this.opts.xAxis.find((d) => d.default);
      if (axis === undefined) return;

      // This part checks if the slot has max or min values.
      // If any we return after this and we do not need to
      // calculate the max and minimum value
      let hasValues = false;
      const maxMeta = this.getMeta(this.metaKeys.MAX, slotId);
      if (maxMeta !== undefined) {
        axis.max = parseFloat(maxMeta.value);
        hasValues = true;
      }

      const minMeta = this.getMeta(this.metaKeys.MIN, slotId);
      if (minMeta !== undefined) {
        axis.min = parseFloat(minMeta.value);
        hasValues = true;
      }

      if (hasValues) return;

      var data = series.data.map((d) =>
        parseFloat(isVertical ? d.value[0] : d.value[1])
      );

      const max = Math.max(...data);
      const min = Math.min(...data);

      if (axis.max === undefined || axis.max < max) axis.max = max.toFixed(1);
      if (axis.min === undefined || axis.min > min) axis.min = min.toFixed(1);
    },

    setColors(options) {
      for (var series of options.series) {
        if (series.slotId === undefined) {
          console.error("Could not find slot in series");
          continue;
        }

        let color = this.getMeta(
          this.templateColorKeys.PER_SLOT,
          series.slotId
        );
        if (color === undefined) continue;

        series.itemStyle.color = color.value;
        if (this.chartType(series.slotId) == "area") {
          series = this.setAreaColor(series, color.value);
        }
      }

      // Fonts and stuff
      options.xAxis.splitLine.color = this.cmpTextColor;
      for (var ax of options.yAxis) {
        ax.axisLabel.color = this.cmpTextColor;
        ax.axisLine.lineStyle.color = this.cmpTextColor;
      }

      if (options.xAxis.length !== undefined)
        for (var ax of options.xAxis) {
          ax.axisLabel.color = this.cmpTextColor;
          ax.axisLine.lineStyle.color = this.cmpTextColor;
        }
      else {
        options.xAxis.axisLabel.color = this.cmpTextColor;
        options.xAxis.axisLine.lineStyle.color = this.cmpTextColor;
      }

      options.legend.textStyle = { color: this.cmpTextColor };

      return options;
    },

    fakeData() {
      let i = 0;
      const isVertical = this.getMetaValueAsBool(this.metaKeys.VERTICAL);
      for (var slt of this.widget.slots) {
        this.seriesData[slt.slotId] = this.generateFakeData(
          5 + i * 5,
          isVertical
        );
        i++;
      }
    },

    async fetchData() {
      const isVertical = this.getMetaValueAsBool(this.metaKeys.VERTICAL);
      for (var slt of this.widget.slots) {
        // Api request to fetch data
        let tagId = this.tags[slt.index - 1];
        let key = slt.key;

        // Get default start time or the set starttime from meta
        var date = this.getFromDateOrDefault();
        if (this.chartType(slt.slotId) === "trend")
          try {
            let response = await tagDataRepository.getTrendDataForTag(
              tagId,
              encodeURIComponent(key),
              MetricHelper.getMetricsUrlParams({
                from: this.toApiDate(date),
                to: this.toApiDate(new Date()),
              })
            );

            this.seriesData[slt.slotId] = response.data.map((e) => {
              return {
                name: e.date,
                value: [new Date(e.date), e.value],
              };
            });
          } catch {
            console.error(
              `Could not get trend data for tag: ${tagId} with key ${key}`
            );
            this.seriesData[slt.slotId] = [];
            continue;
          }
        else
          try {
            let response = await tagDataRepository.getTimeSeriesTagDataSingle(
              tagId,
              key,
              this.toApiDate(date, true),
              this.toApiDate(new Date(), true)
            );

            this.seriesData[slt.slotId] = response.data.map((e) => {
              return {
                name: e.date,
                value: isVertical
                  ? [e.value, new Date(e.date)]
                  : [new Date(e.date), e.value],
              };
            });
          } catch {
            console.error(`Could not fetch data for ${tagId} from api`);
            this.seriesData[slt.slotId] = [];
            continue;
          }
      }
    },

    connectDataToSeries() {
      for (var slt of this.widget.slots) {
        var series = this.opts.series.find((d) => d.slotId == slt.slotId);
        if (series === undefined) continue;

        series.data = this.seriesData[slt.slotId] ?? [];
        this.autoSetMaxMin(slt.slotId);
      }
    },
  },

  async created() {
    if (this.tags.length <= 0) this.fakeData();
    else await this.fetchData();

    this.setupOptions();
  },
};
</script>