var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('action-for-value',{attrs:{"label":_vm.$t('colorPicker.colorForExpressionLabel'),"hide-action-selector":"","one-color":""},on:{"input":(v) => {
          _vm.addMeta(
            this.templateColorKeys.COLOR_FOR_EXPRESSION,
            v,
            undefined,
            'content',
            undefined,
            true
          );
        }}})],1),_c('div',{staticClass:"d-flex flex-wrap justify-start align-center my-2"},_vm._l((_vm.getMetas(this.templateColorKeys.COLOR_FOR_EXPRESSION)),function(item){return _c('action-for-value-chip',{key:item.templateWidgetMetaId,staticClass:"ma-1",attrs:{"content":_vm.tryParseJson(item.content)},on:{"remove":function($event){return _vm.removeMeta(item.templateWidgetMetaId)}}})}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }