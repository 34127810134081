import {
  mdiAccount,
  mdiAccountBox,
  mdiAirConditioner,
  mdiAirFilter,
  mdiAirPurifier,
  mdiAlarmLight,
  mdiAlarmLightOff,
  mdiAlarmLightOffOutline,
  mdiAlarmLightOutline,
  mdiAlarmPanel,
  mdiAlignHorizontalCenter,
  mdiAlignHorizontalLeft,
  mdiAlignHorizontalRight,
  mdiAlignVerticalBottom,
  mdiAlignVerticalCenter,
  mdiAlignVerticalTop,
  mdiAlpha,
  mdiAlphaA,
  mdiAlphaB,
  mdiAlphaC,
  mdiAlphaD,
  mdiAlphaE,
  mdiAlphaF,
  mdiAlphaG,
  mdiAlphaH,
  mdiAlphaI,
  mdiAlphaJ,
  mdiAlphaK,
  mdiAlphaL,
  mdiAlphaM,
  mdiAlphaN,
  mdiAlphaO,
  mdiAlphaP,
  mdiAlphaQ,
  mdiAlphaR,
  mdiAlphaS,
  mdiAlphaT,
  mdiAlphaU,
  mdiAlphaV,
  mdiAlphaW,
  mdiAlphaX,
  mdiAlphaY,
  mdiAlphaZ,
  mdiAmbulance,
  mdiAmplifier,
  mdiAnchor,
  mdiAnimation,
  mdiAntenna,
  mdiApplication,
  mdiApplicationArray,
  mdiApps,
  mdiArchive,
  mdiArrowAll,
  mdiArrowDown,
  mdiArrowLeft,
  mdiArrowRight,
  mdiArrowUp,
  mdiBagSuitcase,
  mdiBank,
  mdiBarcode,
  mdiBarn,
  mdiBattery,
  mdiBattery10,
  mdiBattery50,
  mdiBeaker,
  mdiBed,
  mdiBell,
  mdiCar,
  mdiHelp,
  mdiThermometer,
  mdiThermometerAlert,
} from "@mdi/js";

export default {
  data() {
    return {
      iconUrlCache: [],
    };
  },

  computed: {
    defaultRoundedIcon() {
      return this.circleBorderedIcon("white", "white");
    },

    ICONTYPE() {
      return {
        DEFAULT: "ic-default",
        CIRCLE_CUSTOM: "ic-custom",
        POSITION_ICON: "ic-position-icon",
      };
    },

    mdiIconsKeys() {
      return Object.keys(this.mdiIcons);
    },

    defaultMdiIconKey() {
      return "help";
    },

    mdiIcons() {
      return {
        ["thermometer-alert"]: mdiThermometerAlert,
        ["thermometer"]: mdiThermometer,
        ["account-box"]: mdiAccountBox,
        ["account"]: mdiAccount,
        ["air-conditioner"]: mdiAirConditioner,
        ["air-filter"]: mdiAirFilter,
        ["air-purifier"]: mdiAirPurifier,
        ["alarm-light-off-outline"]: mdiAlarmLightOffOutline,
        ["alarm-light-off"]: mdiAlarmLightOff,
        ["alarm-light-outline"]: mdiAlarmLightOutline,
        ["alarm-light"]: mdiAlarmLight,
        ["alarm-panel"]: mdiAlarmPanel,
        ["align-horizontal-center"]: mdiAlignHorizontalCenter,
        ["align-horizontal-left"]: mdiAlignHorizontalLeft,
        ["align-horizontal-right"]: mdiAlignHorizontalRight,
        ["align-vertical-bottom"]: mdiAlignVerticalBottom,
        ["align-vertical-center"]: mdiAlignVerticalCenter,
        ["align-vertical-top"]: mdiAlignVerticalTop,
        ["alpha-a"]: mdiAlphaA,
        ["alpha-b"]: mdiAlphaB,
        ["alpha-c"]: mdiAlphaC,
        ["alpha-d"]: mdiAlphaD,
        ["alpha-e"]: mdiAlphaE,
        ["alpha-f"]: mdiAlphaF,
        ["alpha-g"]: mdiAlphaG,
        ["alpha-h"]: mdiAlphaH,
        ["alpha-i"]: mdiAlphaI,
        ["alpha-j"]: mdiAlphaJ,
        ["alpha-k"]: mdiAlphaK,
        ["alpha-l"]: mdiAlphaL,
        ["alpha-m"]: mdiAlphaM,
        ["alpha-n"]: mdiAlphaN,
        ["alpha-o"]: mdiAlphaO,
        ["alpha-p"]: mdiAlphaP,
        ["alpha-q"]: mdiAlphaQ,
        ["alpha-r"]: mdiAlphaR,
        ["alpha-s"]: mdiAlphaS,
        ["alpha-t"]: mdiAlphaT,
        ["alpha-u"]: mdiAlphaU,
        ["alpha-v"]: mdiAlphaV,
        ["alpha-w"]: mdiAlphaW,
        ["alpha-x"]: mdiAlphaX,
        ["alpha-y"]: mdiAlphaY,
        ["alpha-z"]: mdiAlphaZ,
        ["alpha"]: mdiAlpha,
        ["ambulance"]: mdiAmbulance,
        ["amplifier"]: mdiAmplifier,
        ["anchor"]: mdiAnchor,
        ["animation"]: mdiAnimation,
        ["antenna"]: mdiAntenna,
        ["application-array"]: mdiApplicationArray,
        ["application"]: mdiApplication,
        ["apps"]: mdiApps,
        ["archive"]: mdiArchive,
        ["arrow-all"]: mdiArrowAll,
        ["arrow-down"]: mdiArrowDown,
        ["arrow-left"]: mdiArrowLeft,
        ["arrow-right"]: mdiArrowRight,
        ["arrow-up"]: mdiArrowUp,
        ["bag-suitcase"]: mdiBagSuitcase,
        ["bank"]: mdiBank,
        ["barcode"]: mdiBarcode,
        ["barn"]: mdiBarn,
        ["battery-10"]: mdiBattery10,
        ["battery-100"]: mdiBattery,
        ["battery-50"]: mdiBattery50,
        ["beaker"]: mdiBeaker,
        ["bed"]: mdiBed,
        ["bell"]: mdiBell,
        ["car"]: mdiCar,
        ["help"]: mdiHelp,
      };
    },
  },

  methods: {
    // c == get from cache
    c_iconMeta(key, background, iconColor) {
      let result = this.iconUrlCache.find(
        (d) =>
          d.key == key && d.background == background && d.iconColor == iconColor
      );
      return result ?? undefined;
    },

    // cf == get from cache or fetch
    cf_iconUrl(
      key,
      background = undefined,
      iconColor = undefined,
      type = undefined
    ) {
      // If the key of the icon doesn't exist set the default key
      let icKey = key;
      if (key !== "position-icon-2" && this.mdiIcons[icKey] === undefined)
        icKey = this.defaultMdiIconKey;

      let cacheResult = this.c_iconMeta(icKey, background, iconColor); // FIXME: Cache result seems to always be undefined
      if (cacheResult === undefined) {
        let url = this.getIconAsUrl(
          type ?? this.ICONTYPE.DEFAULT,
          this.getMdiIcon(icKey),
          background,
          iconColor
        );
        this.iconUrlCache.push({
          key: icKey,
          url: url,
          background: background,
          iconColor: iconColor,
        });
        return url;
      }

      return cacheResult.url;
    },

    getMdiIcon(key) {
      return this.mdiIcons[key] ?? this.mdiIcons[this.defaultMdiIconKey];
    },

    defaultDroppletWithMdiIcon(
      iconPath = undefined,
      iconColor = undefined,
      background = "blue"
    ) {
      return `<svg 
      version="1.1" 
      xmlns="http://www.w3.org/2000/svg" 
      xmlns:xlink="http://www.w3.org/1999/xlink" 
      x="0px" 
      y="0px" 
      width="30px" 
      height="35px" 
      viewBox="0 0 30 35" 
      style="enable-background:new 0 0 30 35;" 
      xml:space="preserve">
      <g>
      <path d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z" id="Shape" fill="${background}" />
      </g>
      <g transform="translate(2, 2)">
        ${
          iconPath !== undefined
            ? `<path d="${iconPath}" fill="${iconColor ?? background}" />`
            : ""
        }
        </g>
      </svg>`;
    },

    roundBorderedIcon(width, height, background, border) {
      return `<svg height="${height}" width="${width}" viewBox="0 0 ${width} ${height}"
        version="1.1" preserveAspectRatio="none" fill="none">
        <rect width="100%" height="100%" fill="none" stroke="none" />
        <ellipse cx="${width / 2}" cy="${height / 2}" rx="${width / 2}" ry="${
        height / 2
      }" fill="${background}" />
        <ellipse cx="${width / 2}" cy="${height / 2}" rx="${
        width / 2 - 4
      }" ry="${height / 2 - 4}" stroke="${border}" stroke-width="3" />
      </svg>`;
    },

    circleBorderedIcon(background, border) {
      return `<svg 
      version="1.1" 
      xmlns="http://www.w3.org/2000/svg" 
      xmlns:xlink="http://www.w3.org/1999/xlink" 
      x="0px" 
      y="0px" 
      width="90px" 
      height="90px" 
      viewBox="-260 352 90 90" 
      style="enable-background:new -260 352 90 90;" 
      xml:space="preserve">
        <g transform="translate(40 -93)">
          <circle fill="${background}" cx="-255" cy="490" r="45"/>
          <circle stroke="${border}" stroke-width="5" fill="none" cx="-255" cy="490" r="38"/>
        </g>
      </svg>`;
    },

    getIconAsUrl(
      type,
      iconPath = undefined,
      background = undefined,
      foreground = undefined
    ) {
      let svgString = "";
      switch (type) {
        case this.ICONTYPE.DEFAULT:
          svgString = this.defaultDroppletWithMdiIcon(
            iconPath,
            foreground ?? "white",
            background ?? "green"
          );
          break;
        case this.ICONTYPE.CIRCLE_CUSTOM:
          if (background === undefined || foreground === undefined) {
            console.error(
              `All required parameters is not met 
              Background: ${background} 
              Border: ${foreground}`
            );
            return undefined;
          }

          svgString = this.circleBorderedIcon(background, foreground);
          break;
        case this.ICONTYPE.POSITION_ICON:
          svgString = `<svg width="50px" height="50px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="${background}" class="bi bi-circle-fill">
        <circle cx="8" cy="8" r="8"/>
      </svg>`;
          break;
        default:
          svgString = svgString = this.defaultDroppletWithMdiIcon(
            iconPath,
            foreground ?? "white",
            background ?? "green"
          );
          break;
      }

      return (
        "data:image/svg+xml;charset=UTF-8;base64, " + window.btoa(svgString)
      );
    },
  },
};
