import widgetMixin from "./widgetMixin";
import { materialColors } from "@/_helpers/materialDesignColors";

export const fillLevelMixin = {
  mixins: [widgetMixin],

  computed: {
    getTarget() {
      return this.getMeta(this.metaKeys.TARGET)?.content ?? undefined;
    },

    base() {
      return {
        legend: {
          show: true,
          data: [],
        },

        xAxis: {
          type: "category",
          axisLabel: {
            color: "#000000",
          },
          splitLine: {
            show: false,
            color: "#000000",
          },
        },

        yAxis: { type: "value", max: 100 },

        series: [
          {
            data: [60],
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.3)",
              borderRadius: 4,
            },
            label: {
              show: true,
              position: "inside",
              color: "#FFFFFF",
            },
            itemStyle: {
              borderRadius: 4,
              color: materialColors[Math.floor(Math.random() * 5 + 1)],
            },
            barMaxWidth: "100%",
            barWidth: "90%",
          },
        ],

        grid: {
          top: 55,
          bottom: 50,
          left: 30,
          right: 30,
        },
      };
    },

    cmpTextColor() {
      return this.contentTextColor?.value ?? this.defaultTextColor;
    },
  },

  methods: {
    getTargetSeries(val) {
      return {
        type: "line",
        data: [],
        itemStyle: { color: "#33691e" },
        markLine: {
          data: [{ yAxis: val }],
          silent: true,
          lineStyle: { width: 3 },
          symbolSize: 10,
          symbol: ["diamond", "diamond"],
          label: {
            fontWeight: "bold",
          },
        },
      };
    },

    setFakeData() {
      this.opts = this.base;

      if (this.opts === undefined)
        console.error(
          "The options is undefined please contact SensorOnline support"
        );

      let fakeVal = 60;
      let fakeTarget = 65;

      this.opts.series[0].data = [fakeVal];
      this.opts.xAxis.data = [
        this.widgetSlots[0].slot + " - " + this.widgetSlots[0].key,
      ]; // TODO: Add ability to use priority of the slots to pick the correct slot
      this.opts.yAxis.min = 0;
      this.opts.yAxis.max = 100;

      // NOTE: If the widget has meta for the target value this should be added with the "getTargetSeries" and push to series
      // NOTE: This might not be the correct way to call "hasTarget" after we have fixed the Slot Prio
      if (this.getTarget)
        this.opts.series.push(this.getTargetSeries(fakeTarget));

      this.setColors(fakeVal);
    },

    setData(data, label) {
      this.opts = this.base;
      if (this.opts === undefined)
        console.error(
          "The options is undefined please contact SensorOnline support"
        );

      this.opts.series[0].data = [data];
      this.opts.xAxis.data = [label];

      this.opts.yAxis.min = this.getMeta(this.metaKeys.MIN)?.value ?? 0;
      this.opts.yAxis.max = this.getMeta(this.metaKeys.MAX)?.value ?? 100;

      // Get target
      if (this.getTarget)
        this.opts.series.push(this.getTargetSeries(this.getTarget));

      this.setColors(data);
    },

    setColors(val) {
      if (this.opts?.series === undefined || this.opts?.series.length <= 0)
        return;

      // Global colors
      this.opts.legend.textStyle = { color: this.cmpTextColor };
      this.opts.xAxis.axisLabel = { color: this.cmpTextColor };
      this.opts.xAxis.axisLine = { lineStyle: { color: this.cmpTextColor } };
      this.opts.yAxis.axisLabel = { color: this.cmpTextColor };
      this.opts.yAxis.axisLine = { lineStyle: { color: this.cmpTextColor } };

      for (var yx of this.opts.series) {
        yx.axisLabel = { color: this.cmpTextColor };
        yx.axisLine = { lineStyle: { color: this.cmpTextColor } };
        yx.label = { show: true, position: "inside", color: this.cmpTextColor };
      }

      // Set the values color
      // Get the color
      let baseColor = this.getMeta(
        this.templateColorKeys.FILLLEVEL_COLOR
      )?.value;
      if (
        baseColor !== undefined &&
        this.opts?.series[0]?.itemStyle?.color !== undefined
      ) {
        // Set the color instead of default material color in base series
        this.opts.series[0].itemStyle.color = baseColor;
      }

      // Evaluate the colors for value color for expression
      baseColor = this.colorForExpressionResult(val);
      if (baseColor !== undefined)
        this.opts.series[0].itemStyle.color = baseColor;
    },
  },
};
