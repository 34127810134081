<template>
  <div style="height: 100%">
    <chart
      v-if="
        isType([widgetTypes.CHART, widgetTypes.MULTICHART], widget.widgetType)
      "
      :widget="widget"
      :tags="tags"
    />
    <latest-value
      v-if="isType(widgetTypes.SINGLE, widget.widgetType)"
      :widget="widget"
      :tags="tags"
    />

    <gauge
      v-if="isType(widgetTypes.GAUGE, widget.widgetType)"
      :widget="widget"
      :tags="tags"
    />

    <cam-online-module
      v-if="isType(widgetTypes.CAMONLINE, widget.widgetType)"
      :module="widget"
    />

    <text-card
      v-if="isType(widgetTypes.TEXT, widget.widgetType)"
      :widget="widget"
    />

    <widget-image-map
      ref="imap"
      v-if="isType(widgetTypes.IMAGEMAP, widget.widgetType)"
      :widget="widget"
    />

    <widget-image
      v-if="isType(widgetTypes.IMAGE, widget.widgetType)"
      :widget="widget"
    />

    <weather
      v-if="isType(widgetTypes.WEATHER, widget.widgetType)"
      :widget="widget"
    />

    <raw-table
      v-if="isType(widgetTypes.RAWTABLE, widget.widgetType)"
      :widget="widget"
      :tags="tags"
    />

    <fill-level
      v-if="isType(widgetTypes.FILLINDICATOR, widget.widgetType)"
      :widget="widget"
      :tags="tags"
    />

    <v-speed-dial absolute bottom right direction="left" v-if="!hideButtons">
      <template v-slot:activator>
        <v-btn v-model="spd" small color="primary" fab>
          <v-icon v-if="spd"> mdi-close </v-icon>
          <v-icon v-else> mdi-cog </v-icon>
        </v-btn>
      </template>
      <v-btn
        @click="() => $emit('open-color-form')"
        fab
        dark
        color="green"
        small
      >
        <v-icon>mdi-palette</v-icon>
      </v-btn>
      <v-btn @click="edit(widget)" fab dark color="accent" small>
        <v-icon>mdi-pen</v-icon>
      </v-btn>
      <v-btn @click="remove" fab color="error" small>
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import widgetMixin from "../../../_helpers/widgetMixin.js";

import Chart from "./Chart.vue";
import LatestValue from "./LatestValue.vue";
import Gauge from "./Gauge.vue";
import CamOnlineModule from "../../camonline/CamOnlineModule.vue";
import TextCard from "./Text.vue";
import WidgetImageMap from "./WidgetImageMap.vue";
import WidgetImage from "./WidgetImage.vue";
import Weather from "./Weather.vue";
import RawTable from "./RawTable.vue";
import FillLevel from "./FillLevel.vue";

export default {
  name: "Widget",

  components: {
    Chart,
    LatestValue,
    Gauge,
    CamOnlineModule,
    TextCard,
    WidgetImageMap,
    WidgetImage,
    Weather,
    RawTable,
    FillLevel,
  },

  mixins: [widgetMixin],

  props: {
    tags: {
      type: Array,
      default: () => [],
    },

    hideButtons: {
      type: Boolean,
      default: false,
    },

    value: {
      default: () => {},
    },
  },

  data() {
    return { spd: false };
  },

  model: { prop: "value" },

  computed: {
    widget: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    ...mapActions("templateDashboard", ["removeWidgetFromState"]),
    edit(widget) {
      // Image map enabler
      if (this.isType(this.widgetTypes.IMAGEMAP, widget.widgetType)) {
        this.$emit("open-image-map-editor");
      } else {
        this.$emit("select-widget", widget);
      }
    },
    remove() {
      this.removeWidgetFromState(this.widget.templateWidgetId);

      // TODO: Remove widget from dashboard
    },
  },
};
</script>
