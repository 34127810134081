var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative","display":"inline"}},[_c('h3',[_vm._v(_vm._s(_vm.label ?? ""))]),_c('div',{staticClass:"d-flex align-center justify-start"},[_c('v-btn',{attrs:{"color":"gray lighten-2 mr-4","x-small":"","dark":"","fab":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c('v-icon',[_vm._v("mdi-palette")])],1),_c('span',{staticClass:"subtitle d-flex"},[_c('div',{staticClass:"color-block color-block-border mr-2",style:(`${
          _vm.localValue !== '' ? 'background-color: ' + _vm.localValue + '' : ''
        }`),on:{"click":function($event){_vm.dialog = true}}}),_vm._v(" "+_vm._s(_vm.localValue)+" ")]),(_vm.localValue !== '')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.localValue = ''}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),_c('div',{class:`${
      _vm.dialog ? 'd-flex flex-wrap' : 'd-none'
    } color-swatches elevation-3`,style:(`background-color: ${_vm.soTheme.background}`)},[_c('v-text-field',{staticClass:"px-2",attrs:{"dense":""},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}}),_vm._l((_vm.colors),function(color,i){return _c('div',{key:i,staticClass:"color-block",style:(`background-color: ${color};`),on:{"click":() => {
          _vm.localValue = color;
          _vm.dialog = false;
        }}})})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }