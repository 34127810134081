<template>
  <div>
    <div>
      <action-for-value
        @input="
          (v) => {
            addMeta(
              this.templateColorKeys.COLOR_FOR_EXPRESSION,
              v,
              undefined,
              'content',
              undefined,
              true
            );
          }
        "
        :label="$t('colorPicker.colorForExpressionLabel')"
        hide-action-selector
        one-color
      />
    </div>

    <div class="d-flex flex-wrap justify-start align-center my-2">
      <action-for-value-chip
        class="ma-1"
        v-for="item in getMetas(this.templateColorKeys.COLOR_FOR_EXPRESSION)"
        :key="item.templateWidgetMetaId"
        :content="tryParseJson(item.content)"
        @remove="removeMeta(item.templateWidgetMetaId)"
      />
    </div>
  </div>
</template>

<script>
import widgetMixin from "../../../_helpers/widgetMixin";

import ActionForValue from "./ActionForValue.vue";
import ActionForValueChip from "./ActionForValueChip.vue";

const defaultData = {
  expression: "",
  color: "",
};

export default {
  name: "ColorForExpression",

  mixins: [widgetMixin],

  components: {
    ActionForValue,
    ActionForValueChip,
  },

  props: {
    label: {
      default: undefined,
    },

    mdlWidget: {
      default: "",
    },
  },

  model: { prop: "mdlWidget" },

  data() {
    return defaultData;
  },

  computed: {
    widget: {
      get() {
        return this.mdlWidget;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    validateExpression() {
      try {
        eval(this.expression);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>