<template>
  <div class="pa-2">
    <!-- 
      1. Show the widget by example as a "Preview" 
      2. Container to show the colors
      3. Form to select colors

      1. Color per slot 
      2. Gauge color
      3. Basic color
      4. Color for value
        - Greater than x color
        - Smaller than x color
        - Equals x color 
    -->
    <div style="min-height: 300px">
      <widget
        :value="widget"
        hide-buttons
        style="height: 300px"
        :key="widgetKey"
      />
    </div>

    <div class="colors-container">
      <div
        v-for="(formType, i) in templateColorsByType(widget.widgetType)"
        :key="i"
      >
        <span class="font-weight-black">
          {{ $t(`colorPicker.${formType}`) }}
        </span>

        <div
          v-if="
            isType(
              [
                templateColorKeys.FILLLEVEL_COLOR,
                templateColorKeys.TITLE_TEXT,
                templateColorKeys.TITLE_BACKGROUND,
                templateColorKeys.CONTENT_TEXT,
                templateColorKeys.CONTENT_BACKGROUND,
              ],
              formType
            )
          "
        >
          <color-picker
            :value="getMeta(formType)?.value"
            @input="
              (val) => {
                addMeta(formType, val);
                forceRerender();
              }
            "
          />
        </div>

        <div v-else-if="formType === templateColorKeys.PER_SLOT">
          <div v-for="(slt, i) in widget.slots" :key="i">
            <span class="subtitle-2 font-weight-bold">
              {{ `Node Slot ${slt.index} - ${slt.key}` }}
            </span>

            <color-picker
              :value="getMeta(formType, slt.slotId)?.value"
              @input="
                (val) => {
                  addMeta(formType, val, slt.slotId);
                  forceRerender();
                }
              "
            />
          </div>
        </div>

        <div v-else-if="formType === templateColorKeys.GAUGE_COLOR">
          <div style="width: 100%" class="d-flex justify-center align-center">
            <color-picker v-model="gaugeColorValue" />
            <v-slider
              v-model="gaugeSliderValue"
              max="100"
              min="0"
              color="accent"
              thumb-label
              dense
              hide-details
            />
            <v-btn @click="addSliderColor" text color="primary">{{
              $t("common.add")
            }}</v-btn>
          </div>
          <div class="d-flex flex-wrap pa-2">
            <div
              v-for="clr in getMetas(formType)"
              :key="clr.templateWidgetMetaId"
              class="d-flex align-center justify-center rounded-lg pa-1 ma-1"
              :style="`background-color: ${clr.value}${
                clr.value.length <= 7 ? '99' : ''
              }; border: 1px solid #9e9e9e39`"
            >
              <span class="ml-2"> {{ clr.content }} % </span>
              <v-btn
                icon
                @click="
                  () => {
                    removeMeta(clr.templateWidgetMetaId);
                    forceRerender();
                  }
                "
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>
          </div>
        </div>

        <div v-else-if="formType === templateColorKeys.COLOR_FOR_EXPRESSION">
          <color-for-expression v-model="widget" />
        </div>

        <v-divider />
      </div>
    </div>
  </div>
</template>

<script>
import widgetMixin from "../../../_helpers/widgetMixin";
import ColorPicker from "./ColorPicker.vue";
import Widget from "../widgets/Widget.vue";

import ColorForExpression from "./ColorForExpression.vue";

export default {
  name: "ColorsForm",

  components: { ColorPicker, Widget, ColorForExpression },

  props: {
    mdlWidget: {
      default: () => {},
    },
  },

  model: {
    prop: "mdlWidget",
  },

  mixins: [widgetMixin],

  data() {
    return {
      widgetKey: 1,
      gaugeColorValue: "#286fa3",
      gaugeSliderValue: 0,
    };
  },

  computed: {
    widget: {
      get() {
        return this.mdlWidget;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    forceRerender() {
      this.widgetKey += 1;
    },

    addSliderColor() {
      let metaId = crypto.randomUUID();
      this.addMeta(
        this.templateColorKeys.GAUGE_COLOR,
        this.gaugeColorValue,
        undefined,
        "value",
        metaId
      );
      this.addMeta(
        this.templateColorKeys.GAUGE_COLOR,
        this.gaugeSliderValue,
        undefined,
        "content",
        metaId
      );
      this.forceRerender();
    },
  },
};
</script>
