<template>
  <grid-layout
    style="
      width: 100% !important;
      min-height: 100%;
      max-height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      margin-bottom: 5rem;
    "
    ref="widgetLayout"
    :layout.sync="widgets"
    :cols="{ lg: 24, md: 24, sm: 12, xs: 12, xxs: 12 }"
    :col-num="24"
    :key="gridKey"
    :row-height="rowHeight"
    :is-draggable="!notForm"
    :is-resizeable="!notForm"
    :responsive="true"
  >
    <grid-item
      v-for="(item, i) in widgets"
      :key="i"
      :x.sync="item.x"
      :y.sync="item.y"
      :w.sync="item.w"
      :h.sync="item.h"
      :i.sync="item.i"
      :static="notForm"
      @resized="resized"
      @moved="moved"
      class="elevation-6"
    >
      <widget
        style="overflow: hidden"
        :value="item"
        :tags="tags"
        :hide-buttons="notForm"
        @input="(val) => updateWidget(i, val)"
        @open-color-form="openColorDialog(item)"
        @open-image-map-editor="openImageMapEditor(item)"
        @select-widget="(w) => $emit('select-widget', w)"
      />
    </grid-item>

    <widget-image-map-editor
      v-model="selectedItem"
      :state="imageMapDialog"
      v-on:state-update="(v) => (imageMapDialog = v)"
    />

    <v-dialog v-model="colorDialog" persistent max-width="600">
      <v-card style="overflow-y: scroll">
        <v-card-title>{{ $t("module.colorFormTitle") }}</v-card-title>
        <colors-form v-if="selectedItem !== undefined" v-model="selectedItem" />
        <v-card-actions>
          <v-btn
            text
            small
            color="gray"
            type="submit"
            @click="closeDialog"
            x-large
            >{{ $t("common.close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </grid-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Widget from "./widgets/Widget.vue";
import VueGridLayout from "vue-grid-layout";
import ColorsForm from "./customForm/ColorsForm.vue";
import WidgetImageMapEditor from "./widgets/ImageMap/WidgetImageMapEditor.vue";
import widgetMixin from "../../_helpers/widgetMixin";

export default {
  components: {
    Widget,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    ColorsForm,
    WidgetImageMapEditor,
  },

  mixins: [widgetMixin],

  props: {
    tags: {
      type: Array,
      default: () => [],
    },

    notForm: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      rowHeight: 30,
      gridKey: 1,
      colorDialog: false,
      imageMapDialog: false,
      selectedItem: undefined,
    };
  },

  computed: {
    ...mapState("templateDashboard", { storeWidgets: "widgets" }),
    ...mapState("tag", ["tagsWithMeta"]),

    widgets: {
      get() {
        return this.storeWidgets;
      },

      set(val) {
        this.setWidgets(val);
      },
    },

    layout: {
      get() {
        let lo = [];

        for (var i = 0; i < this.widgets?.length ?? 0; i++) {
          if (this.widgets[i].i !== undefined) continue;

          var w = {
            i: i,
            ...this.widgets[i],
          };
          lo.push(w);
        }

        return lo;
      },
    },
  },

  methods: {
    ...mapActions("templateDashboard", ["setWidgets"]),
    ...mapActions("tag", ["getTagWithMetaAndKeys"]),

    refresh() {
      this.gridKey += 1;
    },

    updateWidget(index, widget) {
      this.storeWidgets[index] = widget;
    },

    openColorDialog(widget) {
      this.selectedItem = widget;
      this.colorDialog = true;
    },

    openImageMapEditor(widget) {
      this.selectedItem = widget;
      this.imageMapDialog = true;
    },

    closeDialog() {
      this.gridKey += 1;
      this.selectedItem = undefined;
      this.colorDialog = false;
    },

    resized() {
      // TODO: Save layout
    },

    moved() {
      // TODO: Save layout
    },
  },

  async created() {
    if (!this.shoudFake) {
      await this.getTagWithMetaAndKeys(
        this.getDeveuiAndKeysForSlotsFromAllWidgets(this.tags)
      );
    }
  },
};
</script>
