import widgetMixin from "./widgetMixin";

export default {
  mixins: [widgetMixin],

  computed: {
    multiSensor() {
      return this.widget?.slots?.length > 1;
    },

    sparkLine() {
      return this.getMetaValueAsBool(this.metaKeys.SINGLESPARKLINE);
    },

    hideNodeInfo() {
      return this.getMetaValueAsBool(this.metaKeys.SINGLEHIDENODEINFO);
    },

    hideTrendIcon() {
      return this.getMetaValueAsBool(this.metaKeys.HIDETRENDICON);
    },

    horizontalTitle() {
      return this.getMetaValueAsBool(this.metaKeys.HORIZONTALSINGLETITLE);
    },

    decimals() {
      return this.getMeta(this.metaKeys.DECIMAL_ROUNDING)?.value ?? 3;
    },

    prefix() {
      return this.getMeta(this.metaKeys.PREFIX)?.value ?? undefined;
    },

    sufix() {
      return this.getMeta(this.metaKeys.SUFIX)?.value ?? undefined;
    },

    icon() {
      return this.getMeta(this.metaKeys.ICON)?.value ?? undefined;
    },

    valueMatchValue() {
      return this.getMeta(this.metaKeys.VALUEMATCH)?.value ?? undefined;
    },

    valueMatchContent() {
      return this.getMeta(this.metaKeys.VALUEMATCH)?.content ?? undefined;
    },

    hasValueMatch: () => (val) => {
      // Get all valuematch meta and check if any is matching
      return val;
    },

    fontStyle() {
      return {
        title: {
          weight:
            this.getMeta(this.metaKeys.FONT_WEIGHT_TITLE)?.content ??
            "font-weight-bold",
          size: this.getMeta(this.metaKeys.FONT_STYLE_TITLE)?.value ?? 1.17,
        },
        content: {
          weight:
            this.getMeta(this.metaKeys.FONT_WEIGHT_CONTENT)?.content ??
            "font-weight-regular",
          size: this.getMeta(this.metaKeys.FONT_STYLE_CONTENT)?.value ?? 1.5,
        },
      };
    },
  },
};
