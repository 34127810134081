<template>
  <v-card>
    <v-card-text
      class="d-flex flex-wrap justify-space-between align-center"
      v-if="info !== undefined"
    >
      <div class="info-card-chip info-card-primary">
        <span class="subtitle-2 ma-0 pa-0">{{ $t("tag.fields.name") }}:</span>
        <h3 class="heading-6">{{ info.name }}</h3>
        <span class="caption">{{ info.deveui }}</span>
      </div>

      <div class="info-card-chip info-card-primary">
        <span class="subtitle-2 ma-0 pa-0"
          >{{ $t("tagdata.fields.key") }}:</span
        >
        <h3 class="heading-6">{{ info.keyLabel ?? info.key }}</h3>
      </div>

      <div :class="`info-card-chip info-card-primary`">
        <span class="subtitle-2 ma-0 pa-0">{{ $t("tag.fields.type") }}:</span>
        <h3 class="heading-6">
          {{ getFriendlyDecoder(info.decoder) ?? "N/A" }}
        </h3>
      </div>

      <div :class="`info-card-chip info-card-primary`">
        <span class="subtitle-2 ma-0 pa-0"
          >{{ $t("tag.fields.applicationId") }}:</span
        >
        <h3 class="heading-6">
          {{ info.application?.name ?? "N/A" }}
        </h3>
      </div>

      <div :class="`info-card-chip ${batteryColor}`">
        <span class="subtitle-2 ma-0 pa-0"
          >{{ $t("tag.fields.battery") }}:</span
        >
        <h3 class="heading-6">{{ batteryWithSufix ?? "N/A" }}</h3>
      </div>

      <div :class="`info-card-chip ${rssiColor(rssi)}`">
        <span class="subtitle-2 ma-0 pa-0"
          >{{ $t("signalTest.fields.data.rssi") }}:</span
        >
        <h3 class="heading-6">{{ rssi ?? "N/A" }}</h3>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import tagRepository from "../../../../api/repositories/tagRepository";
import imageMapMixin from "../../../../_helpers/imageMapMixin";
import widgetMixin from "../../../../_helpers/widgetMixin";

export default {
  name: "WidgetImageMapDialogContent",

  props: {
    metaId: {
      default: undefined,
    },
    widget: {
      default: undefined,
      required: true,
    },
  },

  mixins: [widgetMixin, imageMapMixin],

  data() {
    return {
      info: undefined,
    };
  },

  methods: {
    async fetchInfo() {
      this.info = undefined;
      if (this.metaId === undefined) return;

      let deveui = this.getAssociatedMeta(
        this.metaKeys.IMAGEMAP_NODE,
        this.metaId
      )?.content;
      if (deveui === undefined) return;

      // Fetch key or na
      let tempInfo = {};

      tempInfo = await tagRepository
        .getTagWithInformation(deveui)
        .then((d) => d.data)
        .catch(() => {
          return undefined;
        });

      let key = this.getAssociatedMeta(
        this.metaKeys.IMAGEMAP_NODE_KEY,
        this.metaId
      )?.content;
      if (key !== undefined) {
        tempInfo.key = key;
        tempInfo.keyLabel = await tagRepository
          .getKeyLabel(undefined, deveui, key)
          .then((d) => d.data)
          .catch(() => key);
      } else {
        tempInfo = "N/A";
      }

      this.info = tempInfo;
    },

    rssiColor(val) {
      if (val === undefined || val === "N/A") return "info-card-grey";

      if (val >= -120 && val < -100) return "info-card-orange";
      if (val < -120) return "info-card-red";
      if (val > -100) return "info-card-green";

      return "info-card-grey";
    },

    getFriendlyDecoder(decoder) {
      if (decoder == "" || !decoder) return "N/A";
      let decoderName = this.decoders.find(
        (d) => d.value == decoder || d.key == decoder
      );
      return decoderName ? decoderName.name : "N/A";
    },
  },

  computed: {
    ...mapState("tag", ["decoders"]),

    rssi() {
      if (this.info?.rssi === undefined || this.info.rssi.trim() == "")
        return undefined;

      return this.info.rssi;
    },

    batteryVal() {
      if (
        this.info?.battery === undefined ||
        this.info.battery.trim() == "" ||
        this.info.battery.trim() === "N/A"
      )
        return undefined;

      let batParsed = parseFloat(this.info.battery);
      if (isNaN(batParsed)) return "N/A";

      return batParsed;
    },

    batteryWithSufix() {
      if (this.batteryVal === undefined || this.batteryVal === "N/A")
        return "N/A";

      let suffix = " V";
      if (this.batteryVal > 1000) suffix = " mV";

      return this.batteryVal.toFixed(2) + suffix;
    },

    batteryColor() {
      let val = this.batteryVal;
      if (val === undefined || val === "N/A") return "info-card-grey";

      let modifier = 1;
      if (val > 1000) modifier = 1000;

      if (val > 3 * modifier) return "info-card-green";
      if (val > 2.4 * modifier) return "info-card-orange";
      return "info-card-red";
    },
  },

  watch: {
    async metaId(val) {
      if (val === undefined) return;

      await this.fetchInfo();
    },
  },

  async created() {
    this.fetchInfo();
  },
};
</script>

<style scoped>
.info-card-chip {
  width: 27%;
  margin: 1rem;

  padding: 1rem 1rem 1rem 1rem;

  height: 100px;

  border: 2px solid;
  border-radius: 10px;

  text-overflow: ellipsis ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.info-card-chip > h3 {
  text-overflow: ellipsis;
  overflow: hidden;
}

.info-card-primary {
  border-color: #2196f3;
}

.info-card-green {
  border-color: #a5d6a7;
}

.info-card-red {
  border-color: #ef5350;
}

.info-card-orange {
  border-color: #ffb74d;
}

.info-card-gray {
  border-color: #bdbdbd;
}
</style>